@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Plus+Jakarta+Sans:ital,wght@0,300..900;1,300..900&family=Space+Grotesk:wght@300..700&display=swap'); */

 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif , "Nunito", sans-serif; ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  scrollbar-color: #e3e7ea hsla(0, 0%, 100%, .01);
    scrollbar-width: thin; 
    font-family: "Nunito", sans-serif;
}

@media print {
  body *:not(#print_content, #print_content *) {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }

  #print_content {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.swiper {
  width: 170px;
  height: 230px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  overflow: hidden;
 
}

 
.accordion{
  --bs-accordion-bg:none !important;
  --bs-accordion-border-color:none !important;
  --bs-accordion-btn-color:none !important; 

}
.accordion-item{
  /* color :white !important;  */
  border-bottom: 2px solid rgb(102 146 255/ 1);
  padding-bottom: .5rem;
}

.accordion-button:not(.collapsed) {
  color: none!important;
  background-color:  none !important;
  box-shadow:  none !important;
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}


 
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #000; /* Adjust as needed */
  opacity: 0.5;
  transition: opacity 0.3s, transform 0.3s; /* Smooth animation */
}

.swiper-pagination-bullet-active {
  opacity: 1;
  transform: scale(1.2); /* Add a scaling effect */
}


.tooltip-inner { 
  max-width: 500px !important;
  padding: 10px !important; 
  font-size: 12px !important; 
  background-color: #fffbe6 !important;
  border: 1px solid #ffe58f !important; 
  color: black !important; 
  text-align: Left !important;
}
.bs-tooltip-bottom .tooltip-arrow::before {
  
 
  border-bottom-color: #ffe58f !important; 
}
 

.speedometer {
  height: 200px!important;
}

img[src*="tracker.metricool.com"] {
  display: none !important;
}
.nsm7Bb-HzV7m-LgbsSe {
  border: none!important;
}
 /* General Styling */
 @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

.resume {
  min-width: 42rem;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.02rem;
  gap: 1rem;
  font-family: "Merriweather", serif;
  padding: .5in;
 

}
.resume * {
  font-family: inherit;
  font-size: 14px;
}
.headerContainer {
  min-width: fit-content;   
   
  color: black; 
  
 
  gap: .3in;

}
.nameashu {
   font-weight: 550;
   font-size: 40px;
   letter-spacing: 0.1rem; 
   text-transform: uppercase; 
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.nameashucontainer {
  display: flex;
  /* flex-direction: column; */ 
  justify-content: center;
  align-items: center;
 
}
.nameashudown {
  font-weight: 550 !important;
  font-size: 19px;
  text-transform: uppercase; 
  letter-spacing: 0.15rem; 
  text-align: center;
 
}
.awardpoint {
  display: flex !important ;
  flex-wrap: wrap; 
  flex-direction: row;
  
  
}
 
.skellcontianer {
  flex-flow: row wrap;
  display: flex;
  gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  
  margin-top: 1rem;
}
.title {
  font-size: 16px;
  font-weight: 600;
  display: block;
  /* letter-spacing: 0.2rem; */
  color:rgb(30, 69, 146) ; 
  text-transform: uppercase; 
  
}
.hr {
  width: 100%;
  height: 0.1rem;
  background-color: white;
  margin-bottom: 0.5rem;

}
.hrtop {
  width: 100%;
  height: 0.2rem;
  background-color: black; 
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.professionalTitle {
  font-size: 14px;
  font-weight: 600;
  display: block;
  color:rgb(30, 69, 146) ; 
 
  text-transform: capitalize; 
  /* add light grey color so that balck font size would see */
  background-color: rgba(176, 196, 242, 0.604);
  padding: .3rem; 

  text-align: center;
  border-radius: 5px;
}
.bew {
  display: flex;
  gap: 10px;
  padding-bottom: .25rem;
}
.resumesampletext {
  object-fit: contain;
  font-weight: 300;
  line-height: 130%;
  color:black; 
  display: flex;
  gap: .3rem;
}
.resumesampletextabout {
  object-fit: contain;  font-weight: 400;
  line-height: 130%;
  display: flex;
  color:black; 
  gap: .3rem;
  margin-bottom: -.7rem;
}
.dot {
  font-weight: 800;
}
.resumesampletexttop {
  font-weight: 400;
  gap: .5rem;  
  text-align: center;
  margin-bottom: 1rem;
}

.resumesampletexttop2 {
  object-fit: contain;
  font-weight: 400;
  line-height: 90%;
  color:black; 
  display: flex;
  gap: .5rem; 
  flex-wrap: wrap;

  align-items:center;

}
.bold {
  font-weight: 500;
}
.bordertop {
  border-top: 1px dotted black;
}
.resumesampleh2 {
  letter-spacing: 0;
  white-space: normal;
  object-fit: contain;
  align-self: center;
  
  font-weight: 600;
  line-height: 150%;
  
} 
.doro {
  display: flex ; 
  flex-direction: column;
  gap: 5px;
}
.removefontwidth {
    font-weight: 400;
}
.user_details {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-right: auto;
  font-weight: 600;
  margin-top: -0.3rem;
}
.ticketcheck {
  margin-right: .2rem;
}
.resume a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.resume a:hover {
  color: #2980b9;
}
.user_details .name {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.3px;
  margin: 0;
}

.user_details .degree_branch_college {

  font-weight: 400;
  line-height: 1.4;
  color: #666;
  margin: 0;
}
.awardsli {
  margin-bottom: -1rem !important; 
   
}
.awardContainer {
  margin-bottom: -1rem;
}
.li {
  padding-bottom: .3rem;
    padding-left: .25rem;
    line-height: 120%;
    font-weight: 400;
    margin: 0;
    text-decoration: none;
    list-style: none;
    display: flex;
}
.li::before {
  content: "•"; /* Custom bullet character */
  color: black; /* Dark color for the bullet */
  font-size: 1.5em; 
  font-weight: bold; /* Make it bolder */
  display: inline-block;
  width: 1em;
  /* margin-left: -1em; Align with the text */
}
.user_details .links_and_info {
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  row-gap: 0.55rem;
  column-gap: 0.5rem;
}

.user_details .info span {
  font-weight: 400;
  color: #666;
}

.user_details .link a {
  font-weight: 400;
}

.user_image {
  max-width: 5rem;
  max-height: 6.5rem;
}

.user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ism_logo {
  width: auto;
  height: 5rem;
}

.ism_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.points{
  padding: 0rem; 
  margin-top: .5rem; 
} 
 
.title{
  font-size: 16px;
  margin-bottom: 0rem;
}
.left {
  height: 100%;
  grid-area: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  /* background-color: #2c3e50; */
  /* color: #ecf0f1; */
}
 
.user_details {
  display: flex;
  flex-direction: column;
}

.noBullets {
  list-style-type: none;
}

.user_details .header_text {
  text-align: center;
}
 

.resumesamplsectionheading {
  text-transform: uppercase;
  
  font-weight: 400;
  line-height: 130%;
}
 
 
/* List Styling */
.section ul {
  list-style-type: none;
  padding: 0;
  margin: 0rem 0; 
 
}

.resumesampletextt {
  object-fit: contain;
  font-weight: 400;
  line-height: 130%;
}

.resumesampletextt2 {

  font-weight: 400;
  line-height: 130%; 
  text-align: end;
}


.resumesampleh3 {
  letter-spacing: 0;
  align-self: center;
  margin-bottom: 0;
  padding-bottom: .25rem;
  font-weight: 700;
  line-height: 17px;
  color: rgb(104, 104, 104);
}
.resumesampleh4 {
  letter-spacing: 0;
  margin-bottom: 0;
  padding-bottom: .25rem;
  font-weight: 700;
  line-height: 17px;
  color: rgb(104, 104, 104); 
 
}


/* Project Styling */
.firstline,
.secondline {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}

.doreeoe {
  display: flex;
  justify-content: space-between;
  
}

.her{
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: rgba(189, 221, 247, 0.397); 
  margin-bottom: .5rem;
 
}
 
.pointsContainer {
  
  margin-bottom: -.7rem;
}
.secondline {
  
  color: #767474;
}
 
.edud {
  display: flex;
  justify-content: space-between;  
  margin-bottom: -.3rem;
}

.description {
  font-style: italic;
}
 
 
.skillsContainer {
  display: flex;
  flex-direction: column; /* Stack each skill block */ 
  
}

 

.skillSet {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute skills with space between */
}

.firstSkillSet {
  width: 30%; /* Set width to fit 3 skills per line */
  margin-right: 10px; /* Space between skills */
}

.otherSkillSet {
  width: 100%; /* Full width for next technical skills */
  margin-bottom: 10px;
}

.skillSet span {
  margin-right: 10px;
}

.eeor {
  display: flex;
  justify-content: space-between;
  width: 100%;
} 


.skillsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin: 10px 0;
}

.skillItem {
  display: flex;
  align-items: center;
  gap: 6px;
}

 

.problemSolutionImapactContainer {
  margin-bottom: 1rem;
}

.problem , .solution , .impact {
  font-weight: 600;
  font-size: 13px;
}

.accomplishments {
  font-weight: 600;
  
}

.responsibilities {
  font-weight: 600;
} 
.container {
    background-color: #F9F9FB; 
     
  min-height: 100vh;
  min-width: 100vw;

}
.ComponentContainer {
    padding: 60px 96px;
}

@media screen and (max-width: 768px) { 

    .ComponentContainer {
        padding: 16px;
    }
}
.main {
    margin-top: 64px;
    /* width: 100%; */
    min-height: 300px;
     
    position: relative;
    overflow: hidden;
}
.main  *{
      font-family: "inter";
} 
.headingContainer {
    width: 100%;
    margin-bottom: 16px;
}
.headingtitle {
    font-size: 24px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-bottom: 24px;
}
.tabContainerWrapper {
    display: flex;
    gap: 14px;
}
.tabContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tab {
    display: flex;
    align-items: center;
    gap: 4px;
     
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.tabNumber {
    color: #909090;
    font-size: 16px;
    font-weight: 600;
}
.activeTab {
    color: #4D4DFF;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 3px solid #4D4DFF;
}
.viewAllContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #4D4DFF;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.horizontalLine {
    height: .5px;
    width: 100%;
    background-color: #ADADFF;
    margin-top:6px;
    
}
.mainContainer {
    
    display: flex;
    gap: 14px;
    flex-wrap: nowrap; /* Prevent wrapping, ensure all items stay in a single row */
    overflow-x: auto; /* Enable horizontal scrolling for the entire container */
    width: 100%; /* Ensure the container takes full width */
    box-sizing: border-box; /* Include padding in width calculation */
  
}

.ScrollContainer {
    min-height: 286px;
    display: flex;
    gap: 24px;
    position: relative;
    flex-wrap: nowrap; /* Prevent wrapping, ensure all items stay in a single row */
    overflow-x: auto; /* Enable horizontal scrolling for the entire container */
    width: 80%; /* Ensure the container takes full width */
    box-sizing: border-box; /* Include padding in width calculation */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
}
.eachContainermain {
     
    height: 325px;
    width: 235px;
    /* padding: 1rem; */
    background-color: #ffffff;
    border-radius: 20px;
    flex: 0 0 auto; /* Ensures the items don't shrink or grow */
    box-sizing: border-box;
    /* Optional overflow properties if the content inside each container overflows */
    overflow-y: hidden;
    overflow-x: hidden;
    border: 1px solid #4D4DFF;
    
}
.ImageContainer {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9BE59;
    border-radius: 50%;  
    position: relative; 
    cursor: pointer;
}
.ImageContainer img {
    height: 92px;
    width: 92px;
    transform: scaleX(-1);
}
.plus {
  position: absolute;
  top: 10%;
  right: -6%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #4D4DFF;
  border-radius: 50%;
 
}
.eachContainer {
    position: relative;
    height: 286px;
    width: 235px;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 12px;
    flex: 0 0 auto; /* Ensures the items don't shrink or grow */
    box-sizing: border-box;
    /* Optional overflow properties if the content inside each container overflows */
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;
    
}

.eachContTitle {
    color: #8F99B5;
    font-size: 12px;

}
.downContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 16px;
}
.innerDownContainer {
    width: 100%;
    height: 58px;
    background-color: #F5F7FF;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
}
.byparLeft:hover {
  color: #5860FC;
  font-weight: 500;
}
.byparLeft {
    height: 100%;
    width: 70%;
    display: flex;
    
    align-items: center;
    font-family: "inter";
    font-size: 14px;
    color: #535D79;
    margin-left: 1rem;
    font-family: "inter";
}
.byparLeftComingSoon {
    height: 100%;
    width: 100%;
    display: flex;
    
    align-items: center;
    font-family: "inter";
    font-size: 14px;
    color: #535D79;
    margin-left: 1rem;
    font-family: "inter";
}
.byparRight {
    height: 100%;
    width: 25%;
    display: flex;
    justify-content:  start;
    align-items: center;

}

 .eachcontainerUp {
    /* height: 75%; */
    /* background-color: #85A5FF; */
    border-radius: 8px;
    overflow: hidden;
 } 

 .eachcontainerUp > .gridScaler {
    transform: scale(1) !important;  /* Adjusted from 0.3 to 1 for normal size */
    transform-origin: center;  /* Centers the scaling */
    width: 100%;
    height: 100%;
 }
 
 .eachcontainerUp img {
    height: auto;
    object-fit: contain;
    width: 100%;
     /* padding-right: 20px;
     padding-left: 20px;
     padding-top: 25.88px; */
    border-radius: 4.88px !important;
 }
 .eachcontainerDown {
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .eachdowninte {
    height: 90%;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
   align-items: center;
}
 .left {
    width: 80%; 
    height: 100%; 
   
    display: flex;
    flex-direction: column;
    justify-content: center;
     font-size: 14px;
     font-family: "inter";
    
 }
 .Right {
    width: 20%;
    height: 100%;
    padding-top:10px ;
 }
 .ttitle {
    clear: #8892AD;
    font-family: "inter";
    font-weight: 400;
 }
 .dore {
   
    color: #535D79;
    font-family: "inter";
 }
 .percentround {
    background-color: #5423E7;
    color: white;
    border-radius: 8px;
    font-size: 11.52px;
    padding: 5.12px;
    width: 32px;
    height: 25px;
    
 }
 .scroll {
    position: absolute;
    top: 50%;
    right: 2rem;
    cursor: pointer;
}
.scroll img {
    height: 3.5rem;
}

 

.eachContainer:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

/* Dim overlay styling */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Dim layer with 30% opacity */
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth dimming transition */
    z-index: 1;
}

.eachContainer:hover .overlay {
    opacity: 1;
}

/* Centered buttons container styling */
.hoverButtons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between buttons */
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2; /* Ensure it appears above the overlay */
}

.eachContainer:hover .hoverButtons {
    opacity: 1;
}

/* Button styling */
.hoverButtons button {
    /* padding: 8px 16px; */
    width: 121px;
    height: 32px;
    background-color: #ffffff;
    color: #494F5C;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s; 
    border-radius: 2rem;
    border: 1px solid #CDD8ED;
    font-size: 14px;
}

.hoverButtons button:hover {
     color: #806EED;
     font-weight: 500;
}
.mobileContainerButton {
    display: none;
    position: relative;
}
.createButton {
    font-size: 16px;
    font-weight: 500;
   padding-top: 12px;
   padding-bottom: 12px;
    
   background-color: #7E61D9;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 16px;
   border-radius: 16px;
   color: #ffffff;
   gap: 8px;
}
.dropdown {
    position: absolute;
    border: 2px solid red;
    width: calc(100% - 16px);
    z-index: 99;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #D8DBE4;
    border-radius: 16px;
    animation: slideDown 0.3s ease forwards; /* Adjust duration and easing as needed */
    opacity: 0; /* Set default to prevent initial flash if styled component */
}
@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.eachBox {
    font-size: 16px;
    color: #535D79;
    padding: 24px;
    border-bottom: 1px solid #D3D9E8;
    
}
.eachBox:last-child {
    border-bottom: none; /* Removes border from the last .eachBox */
}
@media (max-width: 1068px) {
     .main {
        margin-left: 20px;
       
     }
     .mainContainer { 
      flex-direction: column;
     }
   .eachContainermain {
    display: none;
   }
   .ScrollContainer {
    width: 100%;
   }
   .mobileContainerButton {
    display: block;
} 
 }



/* this ui is generated by ai */
.eachContainer {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background: white;
    border: 1px solid #BEBEBE;
    transition: transform 0.2s, box-shadow 0.2s;
    height: 325px;
  }
  
  .eachContainer:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.12);
  }
  
  .overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.4);
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .eachContainer:hover .overlay {
    opacity: 1;
  }
  
  .hoverButtons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 2;
  }
  
  .eachContainer:hover .hoverButtons {
    opacity: 1;
  }
  
  .iconButton {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    background: white;
    color: #806EED;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .iconButton:hover {
    background: #f0ebff;
  }
  
 
  
  .eachcontainerDown {
    padding: 12px 16px;
    background: #ffffff;
    border-top: 1px solid #f0f0f0;
  }
  
 
  
  .left {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .ttitle {
    font-weight: 600;
    color: #2d2d2d;
  }
  
  .eachdowninte {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ttitle {
    font-size: 14px;
    font-weight: 500;
    color: #2d2d2d;
  }
  
  .badge {
    background: #f5f3ff;
    padding: 4px 12px;
    border-radius: 16px;
  }
  
  .badge span {
    font-size: 12px;
    color: #6b46c1;
    font-weight: 500;
  }


  .comingSoonBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: linear-gradient(135deg, #5423E7 0%, #8B5CF6 100%);
    color: white;
    font-size: 10px;
    padding: 5px 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    z-index: 1;
    
    /* Ribbon effect */
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
    }

    /* Right ribbon tail */
    &::before {
        right: 0;
        bottom: -10px;
        border-top: 10px solid #4019AC;
        border-right: 10px solid transparent;
    }

    /* Top ribbon tail */
    &::after {
        left: -10px;
        top: 0;
        border-right: 10px solid #4019AC;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    /* Shine animation */
    background-size: 200% 200%;
    animation: shine 3s linear infinite;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

/* Hover animation */
.innerDownContainer:hover .comingSoonBadge {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.skeleton {
    opacity: 0.7;
    animation: pulse 1.5s infinite;
  }
  
  .skeletonImage {
    width: 100%;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .skeletonTitle {
    width: 150px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .skeletonDate {
    width: 100px;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeletonPercent {
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  } 

  .noDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 325px;
    width: 100%;
    position: relative;
    overflow: hidden;
    
    border-radius: 24px;
    padding: 2rem;
    background-color: #ffffff;
  overflow: hidden;
  }
  
  .noDataContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 2;
  }
  
  .noDataContent img {
    width: 120px; /* Adjust based on the illustration */
    height: auto;
    
    filter: drop-shadow(0 10px 15px rgba(126, 97, 217, 0.1));
    object-fit: contain;
  }
  
  .noDataContent h3 {
    font-size: 24px;
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 0.5rem;
  }
  
  .noDataContent p {
    color: #666;
    font-size: 16px;
    margin-bottom: 2rem;
  }
  
  .createFirstButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    background: linear-gradient(135deg, #4D4DFF 0%, #5423E7 100%);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(126, 97, 217, 0.2);
    transition: all 0.3s ease;
  }
  
  .createFirstButton:hover {
    box-shadow: 0 6px 20px rgba(126, 97, 217, 0.3);
  }
  
  .backgroundElements {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}

.floatingResume {
  position: absolute;
  width: 120px;
  height: 160px;
  background: rgba(126, 97, 217, 0.1);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(126, 97, 217, 0.2);
  backdrop-filter: blur(5px);
  transform-origin: center center;
}

/* Random starting positions and animations for each resume */
.floatingResume:nth-child(1) {
  top: 10%;
  left: 20%;
  animation: float1 25s infinite alternate;
}

.floatingResume:nth-child(2) {
  top: 30%;
  right: 15%;
  animation: float2 28s infinite alternate;
}

.floatingResume:nth-child(3) {
  bottom: 25%;
  left: 35%;
  animation: float3 22s infinite alternate;
}



/* Random movement animations */
@keyframes float1 {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(100px, 50px) rotate(15deg); }
  50% { transform: translate(-50px, 100px) rotate(-10deg); }
  75% { transform: translate(-100px, -50px) rotate(5deg); }
  100% { transform: translate(50px, -100px) rotate(-15deg); }
}

@keyframes float2 {
  0% { transform: translate(0, 0) rotate(0deg); }
  33% { transform: translate(-120px, 80px) rotate(-20deg); }
  66% { transform: translate(80px, -120px) rotate(10deg); }
  100% { transform: translate(-60px, 60px) rotate(-5deg); }
}

@keyframes float3 {
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(150px, -70px) rotate(25deg); }
  100% { transform: translate(-100px, 120px) rotate(-20deg); }
}

 

/* Existing resume styling */
.resumeHeader {
  width: 70%;
  height: 20px;
  background: rgba(126, 97, 217, 0.2);
  border-radius: 4px;
  margin-bottom: 15px;
}

.resumeLine {
  height: 8px;
  background: rgba(126, 97, 217, 0.15);
  border-radius: 2px;
}

.resumeLine:nth-child(1) { width: 100%; }
.resumeLine:nth-child(2) { width: 80%; }
.resumeLine:nth-child(3) { width: 60%; }


  .floatingElement {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: linear-gradient(135deg, #7E61D9 0%, #5423E7 100%);
    opacity: 0.1;
  }
  
  .floatingElement:nth-child(1) { top: 10%; left: 5%; }
  .floatingElement:nth-child(2) { top: 15%; right: 10%; }
  .floatingElement:nth-child(3) { bottom: 20%; left: 15%; }
 
  
  @media (max-width: 768px) {
    .noDataContainer {
      min-height: 300px;
      padding: 1.5rem;
    }
  
    .noDataContent img {
      width: 90px;
      margin-bottom: 1.5rem;
    }
  
    .noDataContent h3 {
      font-size: 20px;
    }
  
    .noDataContent p {
      font-size: 14px;
      margin-bottom: 1.5rem;
    }
  
    .createFirstButton {
      padding: 10px 20px;
      font-size: 14px;
    }
  }

  .proContainer {
    background: linear-gradient(135deg, #2a2171 0%, #4434a0 100%);
    border-radius: 12px;
    padding: 16px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 325px;
    width: 235px;
  }
  
  .proContainer:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .proRibbon {
    position: absolute;
    top: 8px;
    right: -25px;
    background: #ffd700;
    color: #2a2171;
    padding: 3px 25px;
    transform: rotate(45deg);
    font-weight: bold;
    font-size: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .sparkleEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .sparkle {
    position: absolute;
    background: white;
    border-radius: 50%;
    animation: sparkleAnimation 2s infinite;
  }
  
  .sparkle:nth-child(1) { top: 20%; left: 20%; width: 3px; height: 3px; }
  .sparkle:nth-child(2) { top: 40%; left: 60%; width: 4px; height: 4px; animation-delay: 0.3s; }
  .sparkle:nth-child(3) { top: 60%; left: 40%; width: 3px; height: 3px; animation-delay: 0.6s; }
  
  @keyframes sparkleAnimation {
    0% { transform: scale(0) rotate(0deg); opacity: 0; }
    50% { transform: scale(1) rotate(180deg); opacity: 1; }
    100% { transform: scale(0) rotate(360deg); opacity: 0; }
  }
  
  .proTitle {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .proDescription {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 12px;
    font-size: 14px;
  }
  
  .proHighlight {
    color: #ffd700;
    font-weight: 600;
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
  }
  
  .proFeatures {
    list-style: none;
    padding: 0;
    margin: 8px 0;
  }
  
  .proFeatures li {
    margin: 4px 0;
    font-size: 12px;
    opacity: 0.9;
    color: white;
  }
  
  .upgradeButton {
    background: #ffd700;
    color: #2a2171;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .upgradeButton:hover {
    transform: scale(1.05);
  }
  
  .buttonIcon {
    transition: transform 0.2s ease;
    width: 14px;
    height: 14px;
  }
  
  .upgradeButton:hover .buttonIcon {
    transform: translateX(3px);
  }
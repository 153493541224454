:root {
  --background: #ffffff;
  --foreground: #171717;

  /* Colors from Tailwind Config */
  --my-fuse-blue: #4d4dff;
  --my-fuse-deep-blue: #3d3de0;
  --my-fuse-yellow: #f9be59;
  --my-fuse-light-gray: #b4bac9;
  --my-fuse-black: #0f0f0f;
  --my-fuse-slate: #535d79;
  --my-fuse-lavender: #adadff;
  --my-fuse-white: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

/* Import fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Plus+Jakarta+Sans:ital,wght@0,300..900;1,300..900&family=Space+Grotesk:wght@300..700&display=swap');

body {
  color: var(--foreground);
  background: var(--background);
  font-family: "Figtree", Arial, Helvetica, sans-serif;
}

/* Font Classes (Equivalent to Tailwind) */
.figtree {
  font-family: "Figtree", sans-serif;
}

.plus-jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.space-grotesk {
  font-family: "Space Grotesk", sans-serif;
}

/* Dark Mode Utility */
.dark {
  --background: #0a0a0a;
  --foreground: #ededed;
}

/* Range Input Styling */
input[type="range"] {
  appearance: none;
  width: 100%;
  height: 4px;
  background: var(--my-fuse-light-gray);
  border-radius: 2px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 24px;
  height: 24px;
  background: var(--my-fuse-blue);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -10px;
}

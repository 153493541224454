.statusContainer {
    display: flex;
    justify-content: space-between;
    gap: 24px; 
    margin-top: 60px;
}

.statusCard {
    width: 294px; 
    height: 90px; 
    background-color: #fff;
    border-radius: 20px;
    padding: 16px; 
    border: 1px solid #ADADFF; 
    display: flex;
    gap: 32px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}
.statusCardLeft {
    display: flex;
    justify-content: center;
    align-items: center;
}
.statusCardRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.leftcardCircleContainer {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4D4DFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.text {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
 .rightCardTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
 }

/* Add these new mobile responsive styles */
@media screen and (max-width: 1024px) {
    .statusContainer {
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin-top: 30px;
    }

    .statusCard {
        width: calc(50% - 16px);
        min-width: 250px;
        height: 90px;
        padding: 12px;
        gap: 16px;
    }

    .leftcardCircleContainer {
        width: 50px;
        height: 50px;
    }

    .text {
        font-size: 14px;
    }

    .rightCardTextContainer {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .statusContainer {
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-top: 20px;
    }

    .statusCard {
        width: 100%;
        max-width: 320px;
        height: 80px;
        border-radius: 16px;
    }

    .leftcardCircleContainer {
        width: 45px;
        height: 45px;
    }

    .rightCardTextContainer {
        font-size: 15px;
    }
}
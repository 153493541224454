@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Plus+Jakarta+Sans:ital,wght@0,300..900;1,300..900&family=Space+Grotesk:wght@300..700&display=swap');

 
 
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F9FB; 
    font-family: "Figtree";
    
}
.buttonContainer {
    display: flex;
    gap: 16px;
    flex-direction: column;
}
.leftHeader {
    display: flex;
     
    gap: 36px;
}
.button {
    background-color: #4D4DFF;
    color: #fff;
    padding: 16px 32px;
    border-radius: 24px;
    min-width: 240px; 
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 16px;  
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.textContainer{
    display: flex;
    justify-content: center;
     
    flex-direction: column;
}
.welcomeText {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Plus Jakarta Sans";
}
.textParagraph {
    max-width: 300px; 
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.imageContainer {
    width: 120px;
    height: 120px;
    background-color: #F9BE59;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button2 {
    background-color: #fff;
    color: #4D4DFF;
    padding: 16px 32px;
    border-radius: 24px;
    min-width: 240px; 
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 16px; 
    font-weight: 600;
    border: 2px solid #4D4DFF;
    text-transform: uppercase;
    cursor: pointer;
}

 

/* Add media queries for responsive design */
@media screen and (max-width: 768px) {
    .headerContainer {
        flex-direction: column;
        gap: 24px;
        padding: 16px;
    }

    .leftHeader {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 24px;
    }

    .textContainer {
        align-items: center;
    }

    .welcomeText {
        font-size: 24px;
        text-align: center;
    }

    .textParagraph {
        text-align: center;
        max-width: 100%;
    }

    .buttonContainer {
        width: 100%;
    }

    .button, .button2 {
        width: 100%;
        min-width: unset;
        padding: 12px 24px;
    }
}

@media screen and (max-width: 480px) {
    .imageContainer {
        width: 80px;
        height: 80px;
    }

    .welcomeText {
        font-size: 20px;
    }

    .textParagraph {
        font-size: 14px;
    }
}
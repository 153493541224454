/* HeroSection.module.css */

/* Section container */
.heroSection {
  background-color: #f7fafc; /* bg-gray-100 */
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  /* border-bottom: 1px solid var(--my-fuse-lavender); */
  flex-direction: row;
  justify-content: space-between;
  padding-top: 60px;
}
@media (max-width: 767px) {
  .heroSection {
    padding-top: 0px;
    flex-direction: column;
    justify-content: center;
  }
}

/* Mobile layout */
.mobileContainer {
  width: 100%;
  padding: 0 1.5rem; /* px-6 */
  padding-top: 175px;
}
.mobileRow1 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mobileGifContainer {
  position: relative;
  width: 89px;
  height: 100px;
}
.mobileCircleContainer {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.mobileCircle {
  background-color: #f9be59;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.mobileGifImageContainer {
  position: absolute;
  top: 10%;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  left: -0.25rem; /* -left-1 */
}

.mobileHeadingContainer {
  flex: 1;
}
.mobileHeading {
  font-size: 1.875rem; /* text-3xl */
  font-weight: bold;
  color: #000;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.myFuseBlue {
  color: #4d4dff;
}
.mobileSubtextContainer {
  margin-top: 1rem; /* mt-4 */
}
.mobileSubtext {
  font-size: 1rem; /* text-base */
  color: var(--my-fuse-slate);
  font-family: "Figtree", sans-serif;
  font-weight: 500;
}
.mobileButtons {
  margin-top: 1rem; /* mt-4 */
  display: flex;
  gap: 1rem; /* gap-4 */
  justify-content: center;
}

/* Desktop layout */
.desktopLeft {
  width: 50%;
  padding-top: 6rem; /* pt-24 */
  padding-bottom: 92px; /* pb-24 */
  padding-left: 6rem; /* pl-24 */
  padding-right: 2rem; /* pr-8 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.desktopGifContainer {
  position: relative;
  width: 196px;
  height: 196px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.desktopCircleContainer {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.desktopCircle {
  background-color: #f9be59;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.desktopGifImageContainer {
  position: absolute;
  top: 10%;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  left: -1.25rem; /* -left-5 */
}
.desktopHeading {
  font-size: 3.75rem; /* text-6xl */
  font-weight: bold;
  color: #000;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 105%;
}
@media (max-width: 767px) {
  .desktopHeading {
    font-size: 1.875rem; /* text-3xl */
  }
}
.desktopSubtext {
  color: var(--my-fuse-slate);
  margin-top: 1rem; /* mt-4 */
  font-size: 1.5rem; /* text-2xl */
  font-family: "Figtree", sans-serif;
  font-weight: 500;
}
.desktopButtons {
  margin-top: 1.5rem; /* mt-6 */
  display: flex;
  gap: 1rem;
  border: none;
}

/* Divider for large screens */
.divider {
  display: none;
}
@media (min-width: 1024px) {
  .divider {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(77, 77, 255, 0.7);
  }
}

/* Slider container (right content) */
.sliderContainer {
  width: 50%;
  padding: 7rem 6rem; /* py-28 px-24 */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .sliderContainer {
    width: 100%;
    padding-bottom: 64px !important;
    padding-top: 32px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
.sliderInner {
  width: 100%;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
   color: white;
    width: 100%;
    background-color: #4D4DFF;
    border-radius: 20px; 
    margin-top: 60px;
    padding: 40px;
}
.upgrade{
    display: flex;
   gap: 20px;
    justify-content: center;
    align-items: center;
}
.title {
    font-size: 24px;
    font-weight: 600;
    font-family: 'plus jakarta sans';
}
.description {
    font-size: 20px;
    font-weight: 600;
    font-family: 'figtree'; 
    
}
.button {
    font-size: 16px;
    font-weight: 400;
    font-family: 'figtree';
    background-color: white;
    color: #4D4DFF;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 240px;
    font-weight: 600;
    font-family: 'figtree';
}
.center {
    display: flex;
    justify-content: center;
    align-items: center; 
    max-width: 511px;
}
.left {
    display: flex;
    justify-content: center;
    align-items: center; 
    max-width: 286px;
}
.right {
    display: flex;
    justify-content: end;
    align-items: center; 
    max-width: 240px; 
    margin-left: 8rem;
}
@media screen and (max-width: 768px) {
    .container {
        padding: 20px;
        margin-top: 30px;
    }

    .upgrade {
        flex-direction: column;
        text-align: center;
        gap: 15px;
    }

    .title {
        font-size: 20px;
    }

    .description {
        font-size: 14px;
    }

    .button {
        width: 200px;
        font-size: 14px;
        padding: 8px 16px;
    }
    .right {
        margin-left: 0rem;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Plus+Jakarta+Sans:ital,wght@0,300..900;1,300..900&family=Space+Grotesk:wght@300..700&display=swap');
.main *{
  font-family: "Plus Jakarta Sans" !important;
}
.main {
    width: 158px;
    height: calc(100vh - 80px);
    /* margin-top: 32px; */
    border-right: 0.5px solid #ADADFF;

}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border-radius: 16px; */
    background-color: #ffffff;
    position: relative;
    height: 100%;
}
.logo {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding: 16px;
    cursor: pointer;
}
.dorcolor {
    color: rgb(249, 111, 89);
}

.arrow {
    position: absolute;
    right: -20px;
    top: 80px;
}

.subcontainer {
    width: 100%;
 }
.profileimage {
    border-radius: 50%;
    height: 70px;
}
.penimage {
     height: 40px;
}
.profilecontainer {
    margin-top: 2rem;
 display: flex;
 justify-content: center;
}
.imageContainer {
    position: relative;
}
.imageContainer span {
    position: absolute;
    top:70%;
    right: -12%;
}
.detailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    text-align: center;
}
.name {
    color: #373F54;
    font-size: 18px;
    font-weight: 600;
}
.email {
    color: #545C72;
    
}
.exp {
    color: #545C72;
    line-height: 18px;
}
.navigationContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 4rem;
    padding: 0 8px;
    width: 100%;
}
.ee{
    position: relative;
    width: 100%;
    font-size: 14px;
   
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer; 
    height: 96px;
}
.ee:hover {
    color: #5860FC;
    transform: translateY(-2px);
    background-color: #f5f6ff;
}

.iconLabel {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s ease;
    
}
.active {
    color: #5860FC;
    background-color: #f5f6ff;
}
.bar {
    position: absolute;
    right: -8px;
    top: 0;
    background-color: #5860FC;
    height: 100%; 
    width: 4px;
    border-radius: 10px;
    display: none;
}
.baractive {
    display: block;
}

/* Add these styles to your existing SideNavbar.module.css file */

.proUpgradeContainer {
    position: relative;
    margin-top: 10px;
    padding: 8px 10px;
    background: linear-gradient(135deg, #2a2171 0%, #4434a0 100%);
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .proUpgradeContainer:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .proRibbon {
    position: absolute;
    top: 6px;
    right: -20px;
    background: #ffd700;
    color: #2a2171;
    padding: 2px 20px;
    transform: rotate(45deg);
    font-weight: bold;
    font-size: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .sparkleEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .sparkle {
    position: absolute;
    background: white;
    border-radius: 50%;
    animation: sparkleAnimation 2s infinite;
  }
  
  .sparkle:nth-child(1) { top: 20%; left: 20%; width: 3px; height: 3px; }
  .sparkle:nth-child(2) { top: 40%; left: 60%; width: 4px; height: 4px; animation-delay: 0.3s; }
  .sparkle:nth-child(3) { top: 60%; left: 40%; width: 3px; height: 3px; animation-delay: 0.6s; }
  
  @keyframes sparkleAnimation {
    0% { transform: scale(0) rotate(0deg); opacity: 0; }
    50% { transform: scale(1) rotate(180deg); opacity: 1; }
    100% { transform: scale(0) rotate(360deg); opacity: 0; }
  }
  
  .proContent {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .proIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .proTextContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .proTitle {
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .proDescription {
    color: rgba(255, 255, 255, 0.8);
    font-size: 11px;
  }
  
  .proArrow {
    margin-left: auto;
    transition: transform 0.2s ease;
  }
  
  .proUpgradeContainer:hover .proArrow {
    transform: translateX(3px);
  }

.proImage {
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.5));
}

.proIcon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: #ffd700;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(255, 215, 0, 0.3);
}

.proIcon::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(255, 215, 0, 0.2) 0%, transparent 70%);
  z-index: 0;
}

.proIcon img {
  position: relative;
  z-index: 1;
}
/* Mobile styles */
:root {
  --background: #ffffff;
  --foreground: #171717;

  /* Colors from Tailwind Config */
  --my-fuse-blue: #4d4dff;
  --my-fuse-deep-blue: #3d3de0;
  --my-fuse-yellow: #f9be59;
  --my-fuse-light-gray: #b4bac9;
  --my-fuse-black: #0f0f0f;
  --my-fuse-slate: #535d79;
  --my-fuse-lavender: #adadff;
  --my-fuse-white: #ffffff;
}
.mobileContainer {
  position: relative;
  background-color: #f7fafc; /* bg-gray-100 */
}

.mobileTopBar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 0rem; /* h-4 */
  background-color: #edf2f7; /* bg-gray-200 */
  z-index: 800;
}

.mobileClosedWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 400;
}

.whiteNav {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem; /* py-3 px-4 */
  border-bottom: 1px solid #edf2f7; /* border-b border-gray-200 */
}

.brand {
  color: #000;
  font-size: 1.25rem; /* text-xl */
  font-weight: bold;
  font-family: "Space Grotesk", sans-serif;
  justify-content: center;
  align-items: center;
  display: flex;
}

.purpleBar {
  width: 100%;
  background-color: #4d4dff;
  color: #fff;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; /* py-3 */
  font-family: "Figtree", sans-serif;
  font-weight: 600;
}

/* Overlay (open state) */
.overlayMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 33.3333%; /* h-1/3 */
  background-color: #fff;
  z-index: 410;
  display: flex;
  flex-direction: column;
}

.overlayTopBar {
  width: 100%;
  height: 0rem; /* h-4 */
  background-color: #edf2f7; /* bg-gray-200 */
}

.overlayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem; /* py-3 px-4 */
  border-bottom: 1px solid #edf2f7; /* border-b border-gray-200 */
}

.overlayNavLinks {
  flex: 1;
  display: flex;
  flex-direction: column; 
  padding: 20px;
  background-color: #f7fafc;
}
.desktopNavLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem; /* space-x-10 */
}
.overlayNavLink {
  color: #374151; /* text-gray-700 */
  text-decoration: none;
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  font-size: 16px;
}

/* Desktop styles */
.desktopMain {
  position: relative;
}

.desktopNav {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  /* margin-bottom: 0.75rem;  */
  padding-left: 6rem;
  padding-right: 6rem; /* px-24 */
  padding-top: 1rem;
  padding-bottom: 1rem; /* py-4 */
  border-bottom: 1px solid var(--my-fuse-lavender);
}

.desktopContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktopLinksWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 56px; /* space-x-10 */
}

.desktopNavLink {
  display: flex;
  align-items: center;
  font-size: 16px; /* text-sm */
  gap: 2.5rem; /* space-x-10 */
  justify-content: center;
  font-family: "Plus Jakarta", sans-serif;
  font-weight: 500;
  color: #898989;
  font-size: 16px;
  text-decoration: none; 
  cursor: pointer; 
  border-radius: 5px;
    color: #2c2c2c;
    cursor: pointer;
    font-weight: 500;
    padding: .5rem;
} 
.desktopNavLink:hover {
  color: #000;
}

.desktopBrand {
  color: #000;
  font-size: 1.25rem; /* text-xl */
  font-weight: bold;
  font-family: "Space Grotesk" !important;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.brandHighlight {
  color: var(--my-fuse-yellow) !important; /* text-gray-600 */
  text-decoration: none;
  font-size: 1.25rem; /* text-xl */
  font-weight: bold;
  font-family: "Space Grotesk" !important;
}

.desktopNavLink:hover {
  color: #000;
}

.desktopButton {
  background-color: #4d4dff;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  border-radius: 9999px; /* rounded-full */
  padding: 1rem 2rem; /* py-4 px-8 */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px !important;
  width: 25%;
}

.desktopButton:hover {
  background-color: #3b3bff; /* approximated hover:bg-myFuseDeepBlue */
}

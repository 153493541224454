.main {
    min-width: 100%;
    min-height: 80vh;
    border-radius: 10px;
     
}
.main * {
   font-family: "Plus Jakarta Sans";
}
.header { 
font-size: 20px;
 padding: 1rem; 
 font-weight: 600;
 display: flex;
 justify-content: space-between;
}
.headerTitle {
  font-size: 24px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}
.do {
    color: #e3c04c; 
    margin-left: 10px;
}
.custom {
    max-width: 400px; /* Set a maximum width */
  height: auto;     /* Let the height adjust based on content */
  padding: 10px;    /* Optional padding */
} 
.dor {
    width: 100%;
     height: 1px;
      background-color: #ffe58f;
      margin-top: 1rem ;
}
.dor2 {
  width: 100%;
   height: 1px;
    background-color: #ffe58f;
  
}
.content {
  
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    border-radius: .25rem;
 
}

.jd {
  display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #3C445C;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "inter";
} 
.tot {
  box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.15);
   border-radius: 16px;
  background-color: #ffffff;
  min-height: 613px;
  padding: 24px;
}
 

.textBox {
  width: 100%;
  height: 200px;
  border: none;
  /* resize: none; */
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  color: #6b7280; /* Light gray text color */
  background-color: transparent;
}
.test {
  display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

.buttons {
    font-size: 14px;
    background-color: #4D4DFF; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;
    width: 248px;
    height: 44px;
    cursor: pointer;
}
.buttong {
    padding: .5rem;
    background-color: green; 
    color: white;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; 
     
}
.buttonb {
    padding: .5rem;
    background-color: #886902; 
    color: white;
     font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;   
    cursor: pointer;
}
.buttoncontainwer {
    display: flex;
    justify-content: end;
    margin-top: 16px;
}
.spedocont {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center; 
    width: 100%; 
    position: relative;
  }
  .alllor {
    /* padding: 1rem; */
  } 
  .lso {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  } 
  .ffo {
    font-size: 19px;
  } 
  .dfdf {
   font-size: 16px;
  border: 1px solid #E6DDFF;
   padding-left: 12px;
   padding-right: 8px;
   padding-top: 4px;
   padding-bottom: 4px;
   border-radius: 10rem; 
   color: #737B93;
   font-weight: 500;
  } 
  .dd {
    display: flex;
    align-items: center; 
    flex-wrap: wrap;
    gap: 12px;
  }
  .lloooo {
    font-size: 15px;
    padding: 1rem;
    text-align: center; 
    background-color: antiquewhite; 
    margin-top: 1rem;
    border-radius: 8px;
  }
  .dfld{
   display: flex;
   gap: 10px;
  } 
  .loos {
    list-style: none;
    display: flex;
    justify-content: center;
      overflow: auto;    
      gap: 20px;
  }
  .customCircuit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .percentagetext {
    font-size: 24px; /* Adjust size as needed */
    font-weight: 700;
    margin-bottom: 0.1rem; /* Space between percentage and "Overall Score" */
  }
  
  .scorete {
    font-size: 14px; /* Adjust size as needed */
    font-weight: 400; 
  } 

  .pregresBar {
    margin-left: 2rem;  
    width: 60%; 
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
  }

  .containerp{
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: 6px auto;
     
  }

  .dloooo {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
  } 
  .barp {
    position: relative;
    background-color: #eee;
    height: 8px;
    border-radius: 0.75rem;
    overflow: hidden;
    border-radius: 109px;
  }
  .fillp {
  
    height: 100%;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    transition: width 0.3s ease;
  }

  .ishika {
    display: flex;
  }

  .rowishika {
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     padding: 10px;
     border-top: 1px solid #f0f0f0;
     color: #7b7b7b;
     font-weight: 500;
     font-size: 14px;
  }

  .actiroishika {
    color: rgb(102, 108, 255);
    font-weight: 600;
    border-bottom: 3px solid rgb(102, 108, 255);
  }

  .skillComparison {
    font-size:20px;
    font-weight: 600;
    color: #000;
  }

  .flfo { 
    margin-top: 1rem;
    border-radius: 16px; 
    /* padding-top: 20px;
    padding-bottom: 32px;
    padding-right: 24px;
    padding-left: 24px; */
    cursor: pointer;
    background-color: #F9F9FB;
     display: flex;
     flex-direction: column;
     /* gap: 16px; */
  } 

  .flfoac {
    border: 1px solid  brown;
  }
.opneskil {
  /* padding-bottom: 20px !important; */
  gap: 0 !important;
}
.horizontalline {
  width: 100%;
  height: 1px;
  background-color: #C9C7C7;
}
  .headree {
    font-size: 14px;
    font-weight: 500; 
    display: flex;
    justify-content: space-between;
  } 

  .firstno {
    display: flex;
  }

  .ddepal {
    font-size: 16px !important;
    font-weight: 500; 
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    padding: 20px;
  } 
.ecahScoreMian {
  display: flex;
  align-items: center;
  gap: 10px;
}
  .blinkdot {
    width: 4px;
    height: 25px;
    background-color: brown;
    border-radius: 10px;
    animation: blink 1s infinite; 
    margin-right: 10px;
  }
 
  .full{
    max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
   font-size: 13px;
  }
  .full ul {
    margin-top: 10px;
  }

 .showMoreBtn {
  font-size: 14px;
  color: rgb(64, 64, 245);
  font-weight: 600;
 }
 .proffestionald {
  font-size: 14px;
  border: 1px solid #C9C7C7;
  border-radius: 10px;
  background-color: white;
  margin: 22px 20px;
  margin-bottom: 3px;
 }
 .experienceList {
  font-size: 14px;
 
  border-radius: 10px;
 
  margin: 22px 20px;
  margin-bottom: 3px;
 }
 .aibuttonf {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #4D4DFF;
  font-weight: 600;
  border-radius: 24px; 
  min-width: 249px;
  cursor: pointer;
  border: 1px solid #4D4DFF;
  
  font-size: 16px;
 }
 .boroerr {
  border-radius: 24px; 
  padding: 0.3rem;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4D4DFF;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  min-width: 249px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
 }

 .containerButton {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #4D4DFF;
  border-radius: 2rem;
  width: 167px;
  height: 39px;
  padding: 1px;
  background-color: #4D4DFF;
  padding: 2px;
  box-sizing: border-box;

} 

.containerButtonTab {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #4D4DFF;
  border-radius: 2rem;
  width: 100%;
  height: 42px;
  padding: 1px;
  background-color: #4D4DFF;
  padding: 2px;
  box-sizing: border-box;

}

.buttonActive {
  color: #4D4DFF !important;
  font-weight: 600;
  background-color: white;
}
.innerbutton {
  color: white;
  width: 317px;
  height: 44px;
  border-radius: 2rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;    
  font-weight: 500;
  height: 98%;
   
}

 .sfs {
  font-size: .875rem; /* Adjust size as needed */
  line-height: 1.25rem;
    display: flex;
    justify-content: center;
    color: black;
    /* font-weight: bold; */
    cursor: pointer;
    
} 

.sfsTab {
  font-size: .875rem; /* Adjust size as needed */
  line-height: 1.25rem;
    display: flex;
    justify-content: center;
    color: black;
    font-weight: 600;
    cursor: pointer; 
    margin-bottom: 32px;
    
}

.dfroore {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
 }
 .mainsfr {
  padding: 10px; 
  
 }
 .headingiro {
  font-size: 16px; 
  text-align: center;
  padding: .5rem;
  font-weight: 600;
 }

 .TipsContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  background: white; /* Background color of the main content */
  padding: 1rem;
  min-height: 120px;
  display: flex;
  position: relative;
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Ensure rounded corners on the gradient border */
}
.tipleft {
  width: 30%;
}
.tipleft img {
  width: 100%;
  object-fit: contain;
}
.tipright {
  display: flex;

  justify-content: center;
  flex-direction: column;
  width: 70%;
  padding-left: 15px;
}
.tipheading {
  font-size: 12px;
  color: #7c69e8;
  font-weight: 600;
}
.tippara {
  font-size: 14px;
  color: #60539c;
}
.TipsContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1.5px; /* Adjust to control the border thickness */
  background: linear-gradient(
    90deg,
    #9f7fff 0%,
    #81c6fe 18%,
    #2530ff 37%,
    #43ffe6 56%,
    #fc58db 69%,
    #81c6fe 98%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* Creates a mask for padding */
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Creates the inner transparent area */
}


.container {
  width: 100%;
  min-height: 613px;
  margin: 20px auto;
  padding: 32px 20px;
  font-family: "Plus Jakarta Sans";
  border-radius: 8px;
  background-color: #F9F9FB; /* Light background for card */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: #000; /* Darker text color for label */
  margin-bottom: 8px;
  display: block;
  font-family: "Plus Jakarta Sans";
}

.textBoxContainer {
  position: relative;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #D8DCEC; /* Light gray border */
  height: 505px;
  overflow: hidden;
}

.placeholderText {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #8391B7; /* Light gray text color */
  pointer-events: none;
  white-space: pre-wrap; /* Maintains line breaks */
}

.textBox {
  width: 100%;
  height: inherit;
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  background-color: transparent; /* Transparent to show placeholder */
  z-index: 1;
  position: relative;
}

.SwitchATSEditorContainer {
  border: 1.5px solid #C7CFE3;
  height: 44px;
  border-radius: 10rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.eachSwitch {
  width: 49%;
  height: 36px;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535D79;
}
.activeswitch {
  background-color: #ffffff;
  color: #8061DD;
  font-weight: 500;
}
.scoreContainerMain {
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}


.upparScoreContainer {
 
  position: relative;
  display: flex;
  gap: 16px;
}
.downScoreContainer {
  margin-top: 32px;
  
  height: calc(90% - 145px);
  display: flex;
  flex-direction: row;
  column-gap: 46px;
  flex-wrap: wrap; 
}
 
.ScoreContainer {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}
.score {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.scorepara {
  font-size: 16px;
  font-weight: 600;
  color: #4D4DFF;
}
.eachScoreHeadingContainer {
  width: 45%;
  display: flex;
 
}
  .eachScoreHeadingContainer {
  display: flex;
  /* justify-content: space-between; */
}
.progressDashboard {
  display: flex;
  gap: 24px;
  padding: 20px;
  justify-content: center;
}
.progressCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 20px;
  border-radius: 16px;
  background: #FFFFFF;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.progressBg {
  stroke: rgba(0, 0, 0, 0.1);
}
.progressRing {
  position: relative;
  width: 91px;
  height: 102px; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.progressFill {
  stroke-linecap: round;
  transition: stroke-dashoffset 1s ease;
}

.progressValue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lowProgress {
  stroke: #FF6B6B;
}

.mediumProgress {
  stroke: #FFD93D;
}

.highProgress {
  stroke: #4CAF50;
}

.completeProgress {
  stroke: #8061DD;
}

.progressCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(107, 77, 196, 0.15); 
  z-index: 100;
}

.progressMascot {
  width: 60px;
  height: 60px;
}
.progressMetrics {
  position: relative;
}
.eachScoreHeadingContainerUnder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.eachScoreDotContainer {
  display: flex;
  justify-content: center;
  align-items: center; 
}
.eachScoreDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}
/* Target dots within specific containers */
.eachScoreHeadingContainer:nth-child(1) .eachScoreDot {
  background-color: #5860FC; /* Match Score green */
}

.eachScoreHeadingContainer:nth-child(2) .eachScoreDot {
  background-color: #AC8FFF; /* Relevant Experience blue */
}

.eachScoreHeadingContainer:nth-child(3) .eachScoreDot {
  background-color: #CF8CFF; /* Skills Matched orange */
}

.eachScoreHeadingContainer:nth-child(4) .eachScoreDot {
  background-color: #9DB7FF; /* Overall Skills purple */
}

.eachScoreHeadingContainer:nth-child(5) .eachScoreDot {
  background-color: #FFC4DA; /* Format Readability yellow */
}
.eachScoreHeading {
  font-size: 16px;
  font-weight: 500;
  color: #4C5671;
}
.eachScorePercentage {
  font-size: 16px;
  font-weight: 500;
  color: #4C5671;
  width: 42px;
  background-color: #FFDFD4;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  color: #E04132;
  display: flex;
  justify-content: center;
  align-items: center;
}

 

.circularScoreContainer :global(.CircularProgressbar) {
  margin-top: -1.5rem;
  width: 100% !important;
  height: 100% !important;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modalOverlay.show {
  opacity: 1;
  visibility: visible;
}

.modalContent {
  background: white;
  border-radius: 24px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
}

.headerContent {
  padding: 24px 32px;
  border-bottom: 1px solid #f0f0f0;
  background: #ffffff;
}

.jobTitleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.jobTitle {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  line-height: 1.3;
}

.jobStatus {
  background: #e6f7ff;
  color: #0066cc;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.companyInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.companyLogo {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.companyName {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
}

.locationInfo {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #718096;
  font-size: 14px;
}

.jobHighlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 12px;
  margin: 16px 32px;
}

.highlightItem {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.highlightIcon {
  font-size: 20px;
}

.highlightItem h4 {
  margin: 0;
  color: #4a5568;
  font-size: 14px;
}

.highlightItem p {
  margin: 4px 0 0;
  color: #1a202c;
  font-weight: 500;
}

.jobContent {
  padding: 0 32px 32px;
}

.jobSection {
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e8f0;
}

.jobSection h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sectionIcon {
  font-size: 20px;
}

.skillTags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillTag {
  background: #8061DD;
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
}

.skillTag:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

.requirementsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.requirementCard {
  background: #f8fafc;
  border-radius: 12px;
  padding: 16px;
}

.requirementCard h3 {
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 12px;
}

.requirementCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.requirementCard li {
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
  color: #4a5568;
}

.requirementCard li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #48bb78;
}

.benefitsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

.benefitItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.2s;
}

.benefitItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.modalFooter {
  background: #F8FAFC;
  border-top: 1px solid #E2E8F0;
  padding: 20px 32px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  border-radius: 0 0 24px 24px;
}

.saveButton, .cancelButton {
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.saveButton {
  background: #8061DD;
  color: white;
  border: none;
}

.saveButton:hover {
  background: #6F50D2;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

.cancelButton {
  background: white;
  border: 2px solid #E2E8F0;
  color: #4B5563;
}

.cancelButton:hover {
  border-color: #8061DD;
  color: #8061DD;
}

.buttonSpinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* View Toggle Styles */
.viewToggle {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.toggleButton {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #4a5568;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.toggleButton.active {
  background: #8061DD;
  color: white;
  border-color: #8061DD;
}

/* Raw JD Content Styles */
.rawJDContent {
  padding: 24px;
  background: #f8fafc;
  border-radius: 8px;
  margin: 16px;
}

.rawText {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  color: #2d3748;
}

/* Structured Content Styles */
.jdSection {
  padding: 32px;
  background: white;
  margin: 24px;
  border-radius: 16px;
  border: 1px solid #E2E8F0;
  transition: all 0.2s ease;
}

.jdSection:hover {
  box-shadow: 0 4px 20px rgba(128, 97, 221, 0.08);
}

.jdSection h2 {
  font-size: 22px;
  color: #2d3748;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
}

.skillsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
}

.skillGroup {
  background: #F8FAFC;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #E2E8F0;
  transition: all 0.2s ease;
}

.skillGroup:hover {
  border-color: #8061DD;
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.08);
}

.skillGroup h3 {
  font-size: 18px;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 600;
}

.skillTags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.skillTag {
  background: #EEF2FF;
  color: #4338CA;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  border: 1px solid transparent;
}

.skillTag:hover {
  background: #8061DD;
  color: white;
  transform: translateY(-2px);
}

.qualificationsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.qualificationGroup {
  background: #f8fafc;
  padding: 16px;
  border-radius: 8px;
}

.qualificationGroup h3 {
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 12px;
}

.qualificationGroup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.qualificationGroup li {
  padding: 8px 0;
  color: #4a5568;
  position: relative;
  padding-left: 20px;
}

.qualificationGroup li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #8061DD;
}

.loadingButton {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 250px;
}

.loadingIcon {
  animation: spin 1.5s linear infinite;
}

.loadingContent {
  display: flex;
  align-items: center;
  gap: 4px;
}

.loadingText {
  font-size: 14px;
  white-space: nowrap;
  animation: fadeIn 0.3s ease-in-out;
}

.loadingDots {
  min-width: 24px;
  text-align: left;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.savedJobsWrapper {
  padding: 20px;
  background: #F9F9FB;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitle {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
}

.jobCount {
  color: #6B7280;
  font-size: 14px;
}

.jobsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jobCard {
  padding: 24px;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.jobCard:hover {
  border-color: #4F46E5;
  box-shadow: 0 8px 24px rgba(79, 70, 229, 0.12);
  transform: translateY(-2px);
}

.jobCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #4F46E5;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.jobCard:hover::before {
  opacity: 1;
}

.jobCard::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #4F46E5;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.jobCard:hover::after {
  opacity: 1;
}

.jobHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.companyLogo img {
  width: 56px;
  height: 56px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.jobInfo {
  flex: 1;
}

.jobTitle {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 6px 0;
  line-height: 1.4;
}

.companyName {
  color: #4B5563;
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
}

.matchScore {
  text-align: center;
}

.scoreCircle {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  position: relative;
  background: #F3F4F6;
}

.scoreCircle::after {
  content: '';
  position: absolute;
  inset: 2px;
  border-radius: 50%;
  background: white;
}

.scoreCircle span {
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  color: #111827;
}

.jobDetails {
  background: #F9FAFB;
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
}

.detailItem {
  padding: 4px 0;
}

.jobFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftActions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.atsButton {
  background: transparent;
  color: #4F46E5;
  border: 1px solid #4F46E5;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.atsButton:hover {
  background: #EEF2FF;
  border-color: #4338CA;
  color: #4338CA;
}

.postedDate {
  color: #6B7280;
  font-size: 14px;
  padding: 4px 12px;
  background: #F3F4F6;
  border-radius: 20px;
}

.improveButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #EEF2FF;
  color: #4F46E5;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.improveButton:hover {
  background: #E0E7FF;
  transform: translateY(-1px);
}

.improveButton svg {
  stroke: currentColor;
}

.applyButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #4F46E5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.applyButton:hover {
  background: #4338CA;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .savedJobsWrapper {
    padding: 12px;
  }

  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 16px;
  }

  .headerTitle {
    font-size: 20px;
  }

  .jobCard {
    padding: 16px;
  }

  .jobHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .jobInfo {
    width: 100%;
  }

  .scoreContainer {
    width: 100%;
    justify-content: space-around;
    padding: 12px 0;
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
  }

  .jobDetails {
    flex-direction: column;
    gap: 8px;
    margin: 12px 0;
  }

  .detailItem {
    width: 100%;
    justify-content: flex-start;
  }

  .jobFooter {
    flex-direction: column;
    gap: 12px;
  }

  .leftActions {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .atsButton, 
  .improveButton, 
  .applyButton {
    width: 100%;
    justify-content: center;
  }

  .postedDate {
    width: 100%;
    text-align: center;
  }

  .companyLogo img {
    width: 48px;
    height: 48px;
  }

  .jobTitle {
    font-size: 16px;
  }
}

/* Additional responsive refinements for very small screens */
@media screen and (max-width: 480px) {
  .jobCard {
    padding: 12px;
  }

  .scoreCircle {
    width: 44px;
    height: 44px;
  }

  .scoreCircle span {
    font-size: 12px;
  }

  .detailItem {
    font-size: 13px;
  }

  .atsButton, 
  .improveButton, 
  .applyButton {
    font-size: 13px;
    padding: 8px 12px;
  }
}

/* Landscape mode optimization */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .jobsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }

  .jobCard {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .jobFooter {
    margin-top: auto;
  }
}


.editableInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 4px 0;
}

.editableTextarea {
  width: 100%;
  min-height: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 4px 0;
  resize: vertical;
}

.editableField {
  margin: 8px 0;
}

.editableSkill {
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteButton {
  background: #ff4444;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addButton {
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
}

.saveButton {
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.editableResponsibility, .editableQualification {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin: 8px 0;
}


 

/* Structured Content Styles */
.jdSection {
  padding: 32px;
  background: white;
  margin: 16px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.jdSection:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.jdSection h2 {
  font-size: 20px;
  color: #2d3748;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Editable Fields Styling */
.editableInput {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #E2E8F0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  background-color: #F8FAFC;
}

.editableInput:focus {
  border-color: #8061DD;
  background-color: white;
  box-shadow: 0 0 0 3px rgba(128, 97, 221, 0.1);
  outline: none;
}

.editableTextarea {
  width: 100%;
  min-height: 80px;
  padding: 12px 16px;
  border: 2px solid #E2E8F0;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.6;
  transition: all 0.2s ease;
  background-color: #F8FAFC;
  resize: vertical;
}

.editableTextarea:focus {
  border-color: #8061DD;
  background-color: white;
  box-shadow: 0 0 0 3px rgba(128, 97, 221, 0.1);
  outline: none;
}

/* Skills Section */
.skillsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.skillGroup {
  background: #F8FAFC;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #E2E8F0;
}

.skillGroup h3 {
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.editableSkill {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  background: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}

/* Action Buttons */
.deleteButton {
  background: #FEE2E2;
  color: #DC2626;
  border: none;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.deleteButton:hover {
  background: #FEE2E2;
  color: #B91C1C;
}

.addButton {
  background: #F3F4F6;
  color: #4B5563;
  border: 2px dashed #E5E7EB;
  border-radius: 8px;
  padding: 12px 20px;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.addButton:hover {
  background: #8061DD;
  color: white;
  border-style: solid;
  border-color: #8061DD;
}

/* Save Button */
.saveButton {
  background: #8061DD;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.saveButton:hover {
  background: #6F50D2;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

/* View Toggle Enhancement */
.viewToggle {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 20px;
  background: #F8FAFC;
  border-bottom: 1px solid #E2E8F0;
}

.toggleButton {
  padding: 10px 20px;
  border-radius: 24px;
  border: 2px solid #E2E8F0;
  background: white;
  color: #4B5563;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.toggleButton.active {
  background: #8061DD;
  color: white;
  border-color: #8061DD;
  box-shadow: 0 2px 8px rgba(128, 97, 221, 0.2);
}


.skillsList {
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  /* padding: 16px; */
}

.skillRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.skillRowTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 30px;
  background-color: white;
  font-weight: 600;
  font-size: 12px;
  color: #858585;
}

.skillName {
  font-size: 14px;
  font-weight: 500;
  /* flex: 1; */
  width: 35%;
}

.resumeSkill {
  font-size: 14px;
  font-weight: 500;
  width: 35%;
}
.skillStatus {
  margin: 0 16px;
  display: flex;
  align-items: center;
}

/* Toggle Switch Styles */
.toggleSwitch {
  position: relative;
  width: 44px;
  height: 24px;
  display: inline-block;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

.toggleSwitch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E7EB;
  border-radius: 34px;
  transition: all 0.4s ease;
}

.toggleSwitch label:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.4s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Important: Update these selectors to ensure the checked state works */
.toggleSwitch input:checked + label {
  background-color: #4D4DFF;
}

.toggleSwitch input:checked + label:before {
  transform: translateX(20px);
}

/* Add active state styles */
.toggleSwitch.active label {
  background-color: #4D4DFF;
}

.toggleSwitch.active label:before {
  transform: translateX(20px);
}

/* Hover states */
.toggleSwitch:hover label {
  background-color: #D1D5DB;
}

.toggleSwitch.active:hover label,
.toggleSwitch input:checked:hover + label {
  background-color: #4338CA;
}

/* Status Icons */
.warning {
  color: #FFA500;
}

.error {
  color: #FF0000;
}

.success {
  color: #00FF00;
}

.buttons {
  font-size: 14px;
  background-color: #4D4DFF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10rem;
  width: 248px;
  height: 44px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingProgress {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.progressBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: width 0.3s ease;
}

.loadingIcon {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 6px;
}

.icon {
  animation: pulse 1.5s infinite;
}

.loadingText {
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 8px;
}

.stepText {
  font-weight: 500;
  white-space: nowrap;
}

.progressText {
  font-weight: 600;
  min-width: 40px;
  text-align: right;
}

.scanIcon {
  animation: fadeIn 0.3s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.companyTabs {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding: 0px 20px;
 
 
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.companyTabs::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.companyTab {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: fit-content;
  border: 1px solid transparent;
}

.companyTab:hover {
  background: #e5e7eb;
}

.activeTab {
  background: #fff;
  color: #3b82f6 !important;
}

.companyName {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.roleTitle {
  font-size: 12px;
  color: #6b7280;
}

.activeTab .roleTitle {
  color: #3b82f6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.addedSkills {
  margin-top: 20px;
  padding: 16px;
  border-top: 1px solid #eaeaea;
}

.addedSkillsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 120px;
  overflow-y: auto;
  padding: 8px;
}

.addedSkillsItem {
  display: inline-flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 13px;
  color: #495057;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.addedSkillsItem:hover {
  background-color: #f1f3f5;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
}

/* Custom scrollbar for the skills list */
.addedSkillsList::-webkit-scrollbar {
  width: 6px;
}

.addedSkillsList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.addedSkillsList::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

.addedSkillsList::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.generateContent {
  padding: 16px;
  border-top: 1px solid #eee;
}

.selectedKeywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  padding: 12px;
}

.keywordTag {
  display: inline-flex;
  align-items: center;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.removeKeyword {
  background: none;
  border: none;
  margin-left: 4px;
  cursor: pointer;
  color: #666;
}

.generateButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.generateButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.loadingIndicator {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  background: #f8f8f8;
  border-radius: 4px;
  margin: 16px;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.toggleSwitch input:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
}

.pointItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}

.pointInput {
  flex: 1;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  font-family: inherit;
}

.pointInput:focus {
  outline: none;
  border-color: #4D4DFF;
}

.editableListItem {
  margin-bottom: 12px;
  position: relative;
}

.textareaWrapper {
  width: 100%;
  position: relative;
}

.editableTextarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  min-height: 80px;
  background-color: white;
  transition: border-color 0.2s;
}

.editableTextarea:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.editActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.saveButton {
  padding: 6px 12px;
  background-color: #3B82F6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.saveButton:hover {
  background-color: #2563EB;
}

.pointDisplay {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  border: 1px solid #C9C7C7;
  border-radius: 6px;
  transition: background-color 0.2s;
  background-color: white;
}

.pointDisplay:hover {
  background-color: #F9FAFB;
}

.pointText {
  flex: 1;
  font-size: 14px;
  line-height: 1.5;
}

.itemActions {
  display: flex;
  gap: 8px;
  align-items: center;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.itemActions:hover {
  opacity: 1;
}

.itemActions span {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.itemActions span:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.high {
  color: #EF4444;
  font-weight: 500;
  font-size: 14px;
}

.medium {
  color: #F59E0B;
  font-weight: 500;
  font-size: 14px;
}

.low {
  color: #10B981;
  font-weight: 500;
  font-size: 14px;
}
.scoreContainerMain {
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.resultsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.atsResults {
  padding-right: 16px;
  border-right: 1px solid #eee;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #F0F0F0;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 600;
  color: #1A1A1A;
  position: relative;
  padding-left: 12px;
}

.sectionTitle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: #4D4DFF;
  border-radius: 2px;
}

/* Different colors for different sections */
.atsResults .sectionTitle::before {
  background: #4D4DFF;
}

 

/* Add subtle animation on hover */
.sectionTitle:hover::before {
  height: 28px;
  transition: height 0.2s ease;
}

.scoreCircleWrapper {
  text-align: center;
  margin: 16px 0; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scoreText {
  font-size: 24px;
  font-weight: 700;
  color: #4D4DFF;
}

.scoreNumber {
  font-size: 2.5rem;
  font-weight: 700;
  color: #111827;
  display: block;
}

.scoreLabel {
  font-size: 0.875rem;
  color: #6b7280;
  text-transform: uppercase;
}

.metrics {
  margin-top: 2rem;
}

.metricItemContainer {
  margin-bottom: 12px;
  padding: 12px;
  background: #f8f9fc;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.metricItemContainer::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  transition: all 0.3s ease;
}

/* Color coding based on percentage */
.metricItemContainer[data-score="excellent"]::before {
  background: #10B981; /* Green for 90%+ */
}

.metricItemContainer[data-score="good"]::before {
  background: #3B82F6; /* Blue for 70-89% */
}

.metricItemContainer[data-score="average"]::before {
  background: #F59E0B; /* Orange for 40-69% */
}

.metricItemContainer[data-score="poor"]::before {
  background: #EF4444; /* Red for below 40% */
}

.metricItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.metricLabel {
  font-size: 14px;
  font-weight: 500;
  transition: transform 0.3s ease;
}

.metricValue {
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

/* Color coding for metric values */
.metricValue[data-score="excellent"] {
  color: #10B981;
  background: rgba(16, 185, 129, 0.1);
}

.metricValue[data-score="good"] {
  color: #3B82F6;
  background: rgba(59, 130, 246, 0.1);
}

.metricValue[data-score="average"] {
  color: #F59E0B;
  background: rgba(245, 158, 11, 0.1);
}

.metricValue[data-score="poor"] {
  color: #EF4444;
  background: rgba(239, 68, 68, 0.1);
}

.metricBar {
  height: 4px;
  background: #E5E7EB;
  border-radius: 2px;
  overflow: hidden;
}

.metricFill {
  height: 100%;
  border-radius: 2px;
  transition: width 1s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Color coding for metric bars */
.metricFill[data-score="excellent"] {
  background: #10B981;
  animation: pulseGreen 2s infinite;
}

.metricFill[data-score="good"] {
  background: #3B82F6;
  animation: pulseBlue 2s infinite;
}

.metricFill[data-score="average"] {
  background: #F59E0B;
  animation: pulseOrange 2s infinite;
}

.metricFill[data-score="poor"] {
  background: #EF4444;
  animation: pulseRed 2s infinite;
}

/* Pulse animations */
@keyframes pulseGreen {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

@keyframes pulseBlue {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

@keyframes pulseOrange {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

@keyframes pulseRed {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

/* Hover effects */
.metricItemContainer:hover {
  transform: translateX(4px);
  background: #f1f5f9;
}

.metricItemContainer:hover .metricLabel {
  transform: translateX(4px);
}

.suggestions {
  padding-left: 16px;
}

.suggestionList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.suggestionItem {
  background: #f8fafc;
  border-left: 3px solid #4D4DFF;
  padding: 12px 16px;
  border-radius: 0 8px 8px 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.suggestionItem::before {
  content: '';
  position: absolute;
  left: -3px;
  top: 0;
  width: 3px;
  height: 100%;
  background: currentColor;
  transition: transform 0.3s ease;
  transform: scaleY(0);
  transform-origin: bottom;
}

.suggestionItem:hover {
  background: #f1f5f9;
  transform: translateX(4px) scale(1.01);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.suggestionItem:hover::before {
  transform: scaleY(1);
}

.suggestionItem h3 {
  font-size: 14px;
  font-weight: 600;
   
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.suggestionItem:hover h3 {
  transform: translateX(4px);
}

.suggestionItem p {
  font-size: 13px;
  line-height: 1.5;
  color: #64748b;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: translateY(0);
  opacity: 0.9;
}

.suggestionItem:hover p {
  -webkit-line-clamp: unset;
  transform: translateY(2px);
  opacity: 1;
}

/* Different colors and hover states for different suggestion types */
.suggestionItem.jobTitle {
  border-left-color: #22c55e;
  color: #22c55e;
}

.suggestionItem.location {
  border-left-color: #3b82f6;
  color: #3b82f6;
}

.suggestionItem.experience {
  border-left-color: #f59e0b;
  color: #f59e0b;
}

.suggestionItem.language {
  border-left-color: #8b5cf6;
  color: #8b5cf6;
}

/* Icon animation */
.suggestionItem svg {
  transition: all 0.3s ease;
}

.suggestionItem:hover svg {
  transform: scale(1.1) rotate(5deg);
}

@media (max-width: 768px) {
  .resultsContainer {
    grid-template-columns: 1fr;
  }
  
  .atsResults {
    border-right: none;
    border-bottom: 1px solid #eee;
    padding-right: 0;
    padding-bottom: 16px;
  }
  
  .suggestions {
    padding-left: 0;
    margin-top: 16px;
  }
}

.recheckButton { 
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 30px;
  border: none;
  background: linear-gradient(135deg, #4D4DFF 0%, #3B82F6 100%);
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(77, 77, 255, 0.2);
}

.recheckButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(77, 77, 255, 0.3);
  background: linear-gradient(135deg, #3B82F6 0%, #4D4DFF 100%);
}

.recheckButton:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(77, 77, 255, 0.2);
}

.recheckButton:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  transform: none;
}

.recheckButton svg {
  transition: all 0.3s ease;
}

.spinning {
  animation: spin 1.5s linear infinite;
}

.loading {
  padding-right: 40px;
}

.loadingDots {
  position: absolute;
  right: 16px;
  display: flex;
  gap: 2px;
}

.loadingDots span {
  animation: loadingDots 1.4s infinite;
  opacity: 0;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes loadingDots {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* Add shine effect */
.recheckButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 2rem;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.emptyStateContent {
  max-width: 400px;
}

.emptyStateImage {
  width: 180px;
  height: 180px;
  margin-bottom: 1.5rem;
  object-fit: contain;
}

.emptyStateTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 0.75rem;
}

.emptyStateDescription {
  font-size: 1rem;
  color: #6B7280;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.addJobButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #4D4DFF;
  color: white;
  border: none;
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addJobButton:hover {
  background: #1D4ED8;
}

.addJobButton svg {
  transition: transform 0.2s ease;
}

.addJobButton:hover svg {
  transform: rotate(90deg);
}
.suggestionSummary {
  margin-bottom: 24px;
}

.summaryCard {
  background: #F8F9FC;
  border-left: 3px solid #4D4DFF;
  padding: 12px 16px;
  border-radius: 0 8px 8px 0;
}

.summaryCard span {
  display: block;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 4px;
  font-size: 14px;
}

.summaryCard p {
  color: #6B7280;
  font-size: 13px;
  margin: 0;
}

/* Update existing suggestions list styling */
.suggestionList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}
/* BeforeAfterSlider.module.css */

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 20px; /* approximates rounded-2xl */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
}

.beforeImage {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
}

.afterImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  transition: clip-path 0.2s ease;
}

.sliderHandle {
  position: absolute;
  top: 0;
  height: 100%;
  cursor: ew-resize;
  user-select: none;
  transform: translateX(-50%);
}

.verticalLine {
  width: 0.25rem; /* w-1 */
  background-color: #4d4dff;
  height: 100%;
  pointer-events: none;
}

.circularHandle {
  position: absolute;
  top: 90%;
  transform: translate(-50%, -50%);
  background-color: #4d4dff;
  border-radius: 50%;
  width: 2.5rem; /* w-10 */
  height: 2.5rem; /* h-10 */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconHandle {
  color: #ffffff;
  font-size: 1.5rem; /* approximates text-2xl */
}

.print_only_resume_page {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.preview {
  position: relative;
 /* Same   as templateContainer height */
}
.buttonContainer {
  position: absolute;
  top: -20px;
  right: -20px;
  background: white;
   color: #33363F;
  padding: 10px; 
  border: 0.5px solid #4D4DFF;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; 
  cursor: pointer;
} 
.uploadContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.horizontalLine {
  width: 1px;
  height: 24px;
  background-color: #4D4DFF;
  display: flex;
  flex-direction: column;
}
.templateContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
 
  background-color: white;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  overflow-y: hidden;
  border-bottom: 0.5px solid #ADADFF;
}
.templateRowItem {
  overflow-x: auto;
  padding: 12px;
  padding-right: 24px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.templateRowItemHeader {
 display: flex;
 flex-direction: row;
 background-color: #F7F7F7;
 justify-content: space-between;
 align-items: center;
}
.tabContainer {
  display: flex;
  background-color: #F9F9FB; 
}
.container {
  min-width: 400px;
  width: 100%;
  max-width: 210mm;
  aspect-ratio: 1 / 1.4142;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  font-family: "Merriweather", serif;
}
 .chevronUpDownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
 }

.preview_container {
  background-color: #fff;
}

.preview_image {
  width: 100%;
  height: 100%;
  
  border-radius: 6px;
  box-shadow: 7.97px 7.97px 15.94px rgba(0, 0, 0, 0.15);
  /* overflow: hidden;  */
 

}
.maximizedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.maximizedA4Container {
  position: relative;
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 40px auto;
}

.a4Paper {
  width: 210mm;
  height: 297mm;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.maximizedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: -40px;
  right: 0;
  background: white;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: #f0f0f0;
  transform: scale(1.05);
}

.closeButton:active {
  transform: scale(0.95);
}

.previewContainer {
  padding: 40px;
  padding-top: 250px;
}

.preview_image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.dots {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  width: 10px;
  height: 10px;
  /* border-radius: 50%; */
  transition: background-color 0.3s ease-in-out;
  /* background-color: hsl(222, 13%, 98%); */
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: .2rem;
  
}

.dot.selected {
  width: 12px;
  height: 12px;
  color: orange;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.imageLoaded {
  animation: fadeIn 0.5s ease-in-out;
}

.sfs {
  font-size: .875rem; /* Adjust size as needed */
  line-height: 1.25rem;
    margin-top: 1rem; 
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    color: black;
    /* font-weight: bold; */
    cursor: pointer;
    
}
.containerButton {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #4D4DFF;
  border-radius: 2rem;
  width: 317px;
  height: 43px;
  padding: 1px;
  background-color: #4D4DFF;
  padding: 2px;
  box-sizing: border-box;

}

.innerbutton {
  color: white;
  width: 317px;
  height: 44px;
  border-radius: 2rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;    
  font-weight: 500;
  height: 98%;
   
}

.innerbutton:first-child {
  left: 0;
  transform: translateX(var(--translateXLeft, 0%));
}

.innerbutton:last-child {
  right: 0;
  transform: translateX(var(--translateXRight, 0%));
}

.buttonActive {
  color: #4D4DFF;
  font-weight: 600;
  background-color: white;
}


.downloadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.downloadButton {
  
  color: white;
  font-weight: 500;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
   border-radius: 2rem;
   border: 1.5px solid #4D4DFF; 
   background: #ffffff;
   cursor: pointer;
   font-weight: 600;
   font-size: 16px;
   background-color: #4D4DFF;
   text-transform: uppercase;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modalContainer {
  background: white;
  width: 90%;
  max-width: 550px;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.modalHeader {
  padding: 1.5rem;
  background: #FFF9F6;
  border-bottom: 1px solid #FFE4D9;
  display: flex;
  align-items: center;
  gap: 12px;
}

.modalHeader h3 {
  margin: 0;
  color: #FF4B2B;
  font-size: 1.25rem;
  font-weight: 600;
}

.warningIcon {
  color: #FF4B2B;
}

.modalBody {
  padding: 1.5rem;
}

.modalDescription {
  color: #4B5563;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.tipsContainer {
  background: #F9FAFB;
  padding: 1.25rem;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
}

.tipsContainer h4 {
  color: #374151;
  margin: 0 0 1rem 0;
  font-size: 1rem;
  font-weight: 600;
}

.tipsContainer ul {
  margin: 0;
  padding-left: 1.5rem;
}

.tipsContainer li {
  color: #6B7280;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.4;
}

.modalFooter {
  padding: 1.25rem;
  background: #F9FAFB;
  border-top: 1px solid #E5E7EB;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.primaryButton, .secondaryButton {
  padding: 0.625rem 1.25rem;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.primaryButton {
  background: #FF4B2B;
  color: white;
  border: none;
}

.primaryButton:hover {
  background: #E43D1D;
}

.secondaryButton {
  background: white;
  color: #4B5563;
  border: 1px solid #D1D5DB;
}

.secondaryButton:hover {
  background: #F3F4F6;
}

.templatesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.templatesRow {
  display: flex;
  gap: 0.75rem;
  white-space: nowrap;
  /* min-width: min-content; */
  /* height: 90%; */
}

.templateItem {
  flex: 0 0 110px;
  width: 110px; /* Fixed width */
  height: 156px; /* Fixed height - maintains roughly A4 aspect ratio (1:1.4142) */
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.templateItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.selectedTemplate {
  border: 2px solid #4F46E5;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.1);
}

.templateImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.templateImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.layoutAndFormatContainer {
  color: black;
  display: flex;
  gap: 1rem;
  white-space: nowrap;
  padding: 1rem;
  padding-left: 0px !important;
}
.templateName {
  padding: 12px;
 padding-bottom: 7px;
  display: flex;
  font-size: 16px; 
  font-weight: 600;
  color: black; 
  cursor: pointer;
}

 .eachItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  gap: 1rem;
 }
 .eachItemTitle {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 8px;
 }
 .eachItem {
   width: 210px;
   height: 80px;
   border: 1px solid #C9C7C7;
   border-radius: 8px;
   overflow: hidden;
   margin-top: 8px;
 }
.eachItem:hover {
  cursor: pointer;
  border: 1px solid #4D4DFF;
}
.innerItem {
  padding: 12px;
  font-size: 10px;
}
.innerItemTitle {
  margin-left: 12px;
  font-size: 10px;
  font-weight: 600;
}
.innerItemKeyPoints {
  color: #898989;
}
.eachItemActive {
  border: 1px solid #4D4DFF;
}
.activeTab {
  color: #4D4DFF;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}
 
/* Root Section Container */
.faqSection {
  padding-left: 1.5rem; /* px-6 */
  padding-right: 1.5rem;
  padding-top: 120px; /* py-[120px] */
  padding-bottom: 120px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem; /* gap-8 */
}
@media (min-width: 1024px) {
  .faqSection {
    padding-left: 6rem; /* lg:px-24 */
    padding-right: 6rem;
    flex-direction: row-reverse; /* lg:flex-row-reverse */
    gap: 4rem; /* lg:gap-16 */
  }
}

/* Right Column (FAQ List) */
.faqList {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .faqList {
    width: 50%; /* lg:w-1/2 */
  }
}

/* FAQ Item Container */
.faqItem {
  border-bottom: 1px solid #c2c8db; /* border-[#c2c8db] */
}
.faqItem:first-child {
  padding-bottom: 2.25rem; /* pb-9 */
}
.faqItem:not(:first-child) {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; /* py-9 */
}

/* FAQ Toggle Button */
.faqToggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: "Figtree", sans-serif;
  font-weight: 500; /* font-medium */
}
.faqToggle span {
  font-size: 1.25rem; /* text-xl */
  color: #170f49;
}

/* FAQ Answer Animation Container */
.faqAnswer {
  overflow: hidden;
  padding-top: 1.25rem; /* pt-5 */
}
.faqAnswer p {
  padding-bottom: 1rem; /* pb-4 */
  font-size: 1.125rem; /* text-lg */
  color: #6f6c90;
  font-family: "Figtree", sans-serif;
  line-height: 1.75rem; /* leading-relaxed */
}

/* Mobile 'Get in Touch' Button */
.getInTouchMobile {
  display: none;
}
@media (max-width: 1023px) {
  .getInTouchMobile {
    display: block;
    background-color: #4d4dff; /* myFuseBlue */
    border-radius: 9999px;
    padding-left: 1.25rem; /* px-5 */
    padding-right: 1.25rem;
    padding-top: 0.5rem; /* py-2 */
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: "Figtree", sans-serif;
    font-weight: 600; /* font-semibold */
    color: #fff;
    font-size: 1rem; /* text-base */
    width: 100%;
    align-self: center;
    margin-top: 2rem; /* mt-8 */
  }
}

/* Left Column (Heading & Subheading) */
.faqHeadingCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* gap-6 */
}
@media (min-width: 1024px) {
  .faqHeadingCol {
    width: 50%;
    gap: 2.5rem; /* lg:gap-10 */
  }
}
.headingGroup {
  display: flex;
  flex-direction: column;
  gap: 1.25rem; /* gap-5 */
}
.headingGroup h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: bold;
  font-size: 2.25rem; /* text-4xl */
  color: #4d4dff; /* myFuseBlue */
  text-transform: capitalize;
}
@media (min-width: 1024px) {
  .headingGroup h2 {
    font-size: 3rem; /* lg:text-5xl */
  }
}
.headingGroup p {
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-size: 1.125rem; /* text-lg */
  color: #8e8fa9;
}
@media (min-width: 1024px) {
  .headingGroup p {
    font-size: 1.5rem; /* lg:text-2xl */
  }
}

/* Desktop 'Get in Touch' Button */
.getInTouchDesktop {
  display: none;
}
@media (min-width: 1024px) {
  .getInTouchDesktop {
    display: block;
    background-color: #4d4dff;
    border-radius: 9999px;
    padding: 16px 32px;
    text-transform: uppercase;
    font-family: "Figtree", sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 1rem;
    width: fit-content;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.laptop {
    display: flex !important;
    /* justify-content: center !important; */
     /* align-items: center; */
    /* width: 100%; */
}


.laptop > * {
    margin-right: 15px; /* Adjust as needed */
    margin-left: 15px; /* Adjust as needed */
    transition: all 0.5s ease-in-out; /* Combine transition properties */
    position: relative; /* Ensure proper positioning for pseudo-element */
}

.laptop > *::after {
    content: '';
    background-color: #f96f59;
    display: block;
    width: 0; /* Initially set width to 0 */
    height: 2px;
    position: absolute;
    bottom: 3;
    left: 50%; /* Position at the center */
    transform: translateX(-50%); /* Center horizontally */
    transition: width 0.5s ease-in-out; /* Animate width change */
}

.laptop > *:hover::after {
    width: calc(100% - 2px); /* Expand width on hover */
    transition: width 0.5s ease-in-out; /* Animate width change */
}

.main {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
  background-color: rgba(255, 255, 255, 0.8);
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 10px;
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); */
}
.name {
    color: #1e2532;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 15px;
    list-style: none;
    cursor: pointer; 
  
    /* padding-bottom: 1rem; */
    position: relative;
}
.forhoving {
  position: absolute;
 
  width: 90%;
  height: 2rem;
}
.name:hover {
    color: black;
     
}

.active::after {
    /* Add your styles for active items here */
    content: '';
    background-color: #f96f59;
    display: block;
    width: calc(100% - 1px); /* Expand width on hover */
    height: 2px;
    position: absolute;
    bottom: 0;
  }
.heading {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: "0.8px";
  color: black;
  font-family: "Space Grotesk", sans-serif;
  display: flex;
   
}
.logo {
  height: 2rem;
  width: 2rem;
}
  @media only screen and (max-width: 600px) { 
    .noDisplay{
        display: none !important;
    }
    .main {
     padding: 10px; 
     top: 0px;
     
    }
    
  }

  @media only screen and (min-width: 600px) { 
    .noDisplaylaptop{
        display: none !important;
    }
  }

  .wavecontainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .waveimage {
    width: 2rem;
    height: 2rem;
    /* margin-right: 0.5rem; */
    border-radius: 50%;
    border: 2px solid #eee;
    position: relative;
    z-index: 1;
  }
  
  .wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.1rem;
    height: 2.1rem;
    background-color: rgb(194, 162, 246);
    border-radius: 50%;
    animation: waveAnimation 3s infinite;
    transform: translate(-50%, -50%);
    z-index: 0;
    
  }

  .wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3.1rem;
    height: 3.1rem;
    background-color: #55aa56ba;
    border-radius: 50%;
    animation: waveAnimation 3s infinite;
    transform: translate(-50%, -50%);
    z-index: 0;
    
  } 
  .wave3{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.3rem;
    height: 2.3rem;
    background-color: #55aa56ba;
    border-radius: 50%;
    animation: waveAnimation 3s infinite;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  
  @keyframes waveAnimation {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.7);
      opacity: 0;
    } 
    
  }

  .mobileNav {
    position: absolute;
    left: 0;
    top: -0%;
    z-index: 99; 
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%) ;
    width: 100%;
    /* Initially off-screen */
  }

  
  .open {
    transform: translateX(0%);
    /* Slide in from the right */
  }
  
  .ul {
    width: 80vw;
    height: 100vh;
    border-top-right-radius: 28px;
    background: white;
    z-index: 1; 
    text-decoration: none;
    list-style: none;
    padding: 1rem
  }
  .blankSpace{
    background-color: rgba(27,36,55,.7); 
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    position: absolute;
  } 
  .blankSpaceClose {
    transition: transform 0.3s ease-in-out;
    background-color: transparent;
  }

  @media only screen and (max-width: 600px) { 
     .heading {
      font-size: 18px;
     }
     .headingunder {
      font-size: 20px;
      text-align: center;
      padding: 1rem;
     } 
     .onsmallwidth {
      width: 110%; 
     }
  }

  .loginButton {
    height: 34px;
    background:linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%) ;
    border-radius: 50px;
    justify-content: center;
    color: white;
    font-weight: 700;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    padding: 0 20px;
    margin-left: 15px;
    width: 8rem;
  }
  .credit {
    font-size: 14px;
    font-weight: 500;
    color: #833af7;
    border: 1px solid #a066ff;
    padding: 0 20px;
    border-radius: 50px;
   width: 100%; 
   padding-top: 5px;
   padding-bottom: 5px;
  }
  .registerButton {
    height: 34px;
    border: 1px solid #a066ff;
    border-radius: 50px;
    justify-content: center;
    color: black;
    font-weight: 700;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    padding: 0 20px;
    margin-left: 15px;
    width: 8rem;
  }

    .bellIcon {
      color: #717b9e;
      margin: 10px 10px;
    }
   .chatIcon  {
    color: #717b9e;
    margin: 10px 10px;
   }
  .chatIcon:hover  {
    color: #a066ff !important; 
    cursor: pointer;
  }

  .bellIcon:hover  {
    color: #a066ff !important; 
    cursor: pointer;
  }
  .aiResumeButton {
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%) ;
    background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  .imageSection {
    display:flex;
    justify-content: center;
    align-items: center; 
    position: relative;
     
  }
  .imageSection img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    z-index: 10;
  }
  .imggggg{
    height: 2.2rem !important;
    width: 2.2rem !important;
    border-radius: 50%;
    z-index: 10;
  }
  .mainsim {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #f7f7f9;
    border-top: 1px solid #f7f7f9;

  }
  .textsecion{ 
    display: flex;
    flex-direction: column;
    margin-left: 1.2rem;
    font-weight: 500; 
   color: black; 
   overflow: hidden; 
 
  }

  .textsection span {
    display: block;  
    margin-top: 5px; /* Space between lines */
    overflow: hidden; 
   

  }
  
  .email {
    color: #717b9e;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-top: 3px;
  }
  .nameofmobile{
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: #121224 ;
  } 

  .listitemmain{
    padding: 1rem
  }

  .listitemmain div{
    padding: 4px;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px; 
    margin-top: 15px;
  }

  .viewandupdate {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
    color: rgb(87, 87, 233) ; 
    cursor: pointer;
    border:1px solid rgb(87, 87, 233); 
     border-radius: 18px; 
     padding: 10px 10px ;
  }
  
  .viewandupdate:hover {
     background-color: rgb(87, 87, 233);
     color: white;
     
  }
  .dropdown {
    position: relative;
    display: inline-block;
     }
     .dropdowncontent {
      position: absolute;
      background-color: #f9f9f9;
      padding: 1rem;
      min-width: 260px;
      box-shadow: 0px 8px 16px 0px rgba(0,
      0, 0, 0.2);
      z-index: 1; 
      top: 1.5rem;
      right: -4rem;
      border-radius: 12px; 
      } 


      

   .underline{
    height: 1px;
    background-color: #eee;
    margin-top: 1rem;
   }
   .dropdownItems{
    padding: 1rem;
   }
   .dropdownItem:hover{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    color: white;
    border-radius: 10px;
    }
    
   .dropdownItem{
    color: black;
    padding: 6px 0px;
    font-weight: 400;
    padding:7px;
    cursor: pointer;
    font-size: 14px;
   }

   
     
   .dropdowncontentNoti {
    position: absolute;
    background-color: #f9f9f9;
    padding: 1rem;
    min-width: 360px;
    min-height: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0,
    0, 0, 0.2);
    z-index: 1; 
    top: 2rem;
    right: -4rem;
    border-radius: 12px; 
    } 
    .dropdowncontent{
      position: absolute;
      background-color: #f9f9f9;
      padding: 1rem;
      min-width: 260px;
      min-height: 300px;
      box-shadow: 0px 8px 16px 0px rgba(0,
      0, 0, 0.2);
      z-index: 1; 
      top: 2rem;
      right: -4rem;
      border-radius: 12px; 
      } 
      .dropdowncontentjob{
        position: absolute;
        background-color: #f9f9f9;
        padding: 1rem;
        min-width: 860px;
        min-height: 400px;
        box-shadow: 0px 8px 16px 0px rgba(0,
        0, 0, 0.2);
        z-index: 1; 
        top: 2rem;
        left: -15rem;
        border-radius: 12px;  
        } 
        .dropdowncontentreume{
          position: absolute;
          background-color: white;
          padding: 1rem;
          min-width: 240px;
          /* min-height: 250px;  */
          box-shadow: 0px 8px 16px 0px rgba(0,
          0, 0, 0.2);
          z-index: 1; 
          top: 2rem;
          left: -7rem;
          border-radius: 12px;  
          }  
          .hto {
            font-weight: 600;
            border: 1px solid #eee;
            padding: 7px;
            border-radius: 10px;
          } 
          .hto:hover {
            background-color: #f9f9f9;
            color: #333;
          }

    .notitext{
      font-weight: 500;
    }

    .noitcontent{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      height:inherit; 
      flex-direction: column;
    }
    .jobcontent{
      display: flex;
      justify-content: center;
      padding: 1rem;
      height:inherit; 
      gap: 5px;
      min-height: 100%; 
   
    }
    .resumecontent{
      display: flex;
      /* justify-content: center; */
      padding: .2rem;
      height:inherit; 
      gap: 5px;
      max-height: 60vh;  
      flex-direction: column;
   
    }
    .notino{
      margin-top: 1rem;
      font-weight: 500;
    } 
    .funny {
      font-size: 14px;
      text-align: center;
      margin-top: 1rem;
      color: #918f99; 
      font-weight: 400;
    }
    .jobsdiv{
       
      width: 25%;
      min-height: 100%; 
      display: flex;
      flex-direction: column;

    }
    .jobsheading{
      font-size: 14px;
      font-weight: 500;
      /* text-align: center; */
    }
    .horizantalBar{
      height: 400px;
      width: 1px;
      background-color: #eee;
    }
    .jobsitemss{
      margin-top: 1rem;
    }
    .resumeItems{
      margin-top: 1rem;
      overflow: scroll;
    }
    .jobsSingleItem{
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      position: relative;
      margin-top: 2px;
    }
    .jobsSingleItem:hover{
      /* background-color: #f2f2f2; */
      /* border-radius: 10px; */
      cursor: pointer; 
     color: #5811af;
      font-weight: 700;
    }

    .nameresume{
      font-size: 14px;
      font-weight: 500;
      overflow: scroll;
      width: 18rem;
     
    }
    .resmeim{
        /* border: 2px solid red; */
        width: 40rem; 
      
    }

    .activecmponent{
      background-color: #f2f2f2;
      border-radius: 10px;
      cursor: pointer;
    }

    .comingsoon{
   font-size: 16px;
   padding: 1rem;
     font-weight: 500;
     color: #918f99; 
    }

    .showuseresume {
      display: flex;
      justify-content: space-between;
 
      align-items: center;
      position: relative;
    }
    .aaai{
      position: absolute;
      height: 100%;
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
    }
    .buttonshow{
      background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%) ;
      padding: 7px;
      color: white;
      font-weight: 500;
      border-radius: 7px;
      /* display: none; */
      content: ''; 
      font-size: 14px;
    } 

    .showuseresume:hover .aaai {
      display: flex;
    }

    .shortDescription{
      
       font-size: 12px;
    }
    .viewandupdateContainer{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%); 
      color: white;
      font-size: 13px;
      cursor: pointer;
      font-weight: 500;
      padding: .55rem;
      border-radius: 7px;
    }
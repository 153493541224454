 
.navbarMobile {
    display: none;
    position: sticky;
    top: 0;
    z-index: 100;
    /* background-color: white; */
    width: 100%;
}
 
.container {
    background-color: #F9F9FB; 
   
 

}
.navwrapper {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
}
.ComponentContainer {
    padding: 60px 96px;
}

@media screen and (max-width: 768px) { 

    .ComponentContainer {
        padding: 16px;
    }
    .navbarMobile {
        display: block;
    }
}
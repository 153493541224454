.container {
  padding: 60px 40px;
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9ff 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.headerContent {
  position: relative;
  z-index: 2;
}
.navbarMobile{
  display: none;
}
.headerDecoration {
  position: absolute;
  top: -100px;
  right: -100px;
  width: 400px;
  height: 400px;
  background: linear-gradient(135deg, rgba(128, 97, 221, 0.1) 0%, rgba(107, 77, 230, 0.1) 100%);
  border-radius: 50%;
  filter: blur(60px);
  z-index: 1;
} 

@media (max-width: 768px) {
  .headerDecoration {
    display: none;
  }
}

.header {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 20px;
  position: relative;
}

.header h1 {
  color: #8061DD;
  font-size: 52px;
  margin-bottom: 16px;
  font-weight: 800;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  letter-spacing: -0.5px;
}

.header p {
  color: #757F9B;
  font-size: 20px;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.searchSection {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.dropdown {
  width: 493px;
  position: relative;
}

.dropdownToggle {
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #535D79;
  font-size: 14px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.templatesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px 0;
  justify-items: center;
}

.templateCard {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.templateCard::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(135deg, rgba(128, 97, 221, 0.2), rgba(107, 77, 230, 0.2));
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.imageContainer {
  width: 100%;
  height: 400px;
  max-width: 300px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  position: relative;
  transition: transform 0.3s ease;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 0.5s ease;
}

.imageOverlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(128, 97, 221, 0.2),
    rgba(107, 77, 230, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.templateCard:hover .imageOverlay {
  opacity: 1;
}

.previewButton {
  padding: 16px 32px;
  background: white;
  color: #8061DD;
  border: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease;
}

.templateCard:hover .previewButton {
  transform: translateY(0);
  opacity: 1;
}

.previewButton:hover {
  background: #8061DD;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 12px 28px rgba(128, 97, 221, 0.3);
}

.templateCard:hover .imageContainer {
  transform: scale(1.02);
}

.templateCard:hover img {
  transform: scale(1.05);
}

.imageContainer::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  transition: all 0.5s ease;
  opacity: 0;
}

.templateCard:hover .imageContainer::after {
  animation: shine 1.5s ease;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(100%) rotate(45deg);
    opacity: 0;
  }
}

.buttonIcon {
  transition: transform 0.3s ease;
}

.previewButton:hover .buttonIcon {
  transform: rotate(-12deg) scale(1.2);
}

.recommended .imageContainer::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 18px;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  z-index: -1;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.templateDetails {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.templateName {
  font-size: 24px;
  color: #2A3146;
  font-weight: 700;
  text-align: center;
  transition: color 0.3s ease;
}

.templateCard:hover .templateName {
  color: #8061DD;
}

.templateInfo {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 8px;
}

.templateTag {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  background: #f0f2f5;
  color: #535d79;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.3s ease;
}

.templateTag:hover {
  background: #8061DD;
  color: white;
  transform: translateY(-2px);
}

.templateFeatures {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.featureTag {
  padding: 6px 12px;
  background: #EEE9FF;
  color: #8061DD;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.featureTag:hover {
  background: #8061DD;
  color: white;
  transform: translateY(-2px);
}

.recommended {
  border: 2.5px solid #4CAF50;
  background: linear-gradient(to bottom, #ffffff, #f8fff9);
}

.recommendedBadge {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  overflow: hidden;
  padding: 6px 12px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.recommendedContent {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  z-index: 2;
}

.starIcon {
  color: #FFD700;
  animation: pulse 2s infinite;
}

.recommendedBadge span {
  font-size: 13px;
  font-weight: 600;
  color: #1a1a1a;
  letter-spacing: 0.3px;
}

.recommendedGlow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 215, 0, 0.2) 0%, rgba(255, 215, 0, 0) 70%);
  z-index: 1;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* Large Desktop */
@media screen and (min-width: 1440px) {
  .templatesGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Desktop */
@media screen and (max-width: 1200px) {
  .templatesGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }

  .header h1 {
    font-size: 42px;
  }
}

/* Tablet */
@media screen and (max-width: 992px) {
  .templatesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .container {
    padding: 40px 20px;
  }

  .header h1 {
    font-size: 36px;
  }
}

/* Large Mobile */
@media screen and (max-width: 768px) {
  .templatesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .templateCard {
    max-width: 250px;
    padding: 15px;
  }

  .imageContainer {
    height: 333px;
    max-width: 250px;
  }

  .header h1 {
    font-size: 32px;
  }

  .header p {
    font-size: 16px;
  }

  .previewButton {
    padding: 12px 24px;
    font-size: 14px;
  }

  .recommendedBadge {
    top: 8px;
    right: 8px;
    padding: 4px 8px;
  }

  .recommendedContent {
    gap: 4px;
  }

  .starIcon {
    width: 12px;
    height: 12px;
  }

  .recommendedBadge span {
    font-size: 11px;
  }
}

/* Mobile */
@media screen and (max-width: 576px) {
  .templatesGrid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .container {
    padding: 30px 15px;
  }

  .templateCard {
    max-width: 280px;
  }

  .imageContainer {
    height: 373px;
    max-width: 280px;
  }

  .header h1 {
    font-size: 28px;
  }

  .templateInfo {
    flex-wrap: wrap;
    justify-content: center;
  }

  .templateTag {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* Small Mobile */
@media screen and (max-width: 360px) {
  .templateCard {
    max-width: 250px;
  }

  .imageContainer {
    height: 333px;
    max-width: 250px;
  }

  .header h1 {
    font-size: 24px;
  }

  .previewButton {
    padding: 10px 20px;
    font-size: 13px;
  }
}

/* Handle device orientations */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .container {
    padding: 20px;
  }

  .header {
    margin-bottom: 20px;
  }

  .templatesGrid {
    gap: 15px;
  }

  .imageContainer {
    height: 280px;
  }
}

/* Better touch targets for mobile */
@media (hover: none) and (pointer: coarse) {
  .previewButton {
    padding: 16px 28px;
    min-height: 44px;
  }

  .templateTag,
  .featureTag {
    min-height: 36px;
    display: flex;
    align-items: center;
  }
}

/* Fix for notched devices */
@supports (padding-top: env(safe-area-inset-top)) {
  .container {
    padding-left: max(15px, env(safe-area-inset-left));
    padding-right: max(15px, env(safe-area-inset-right));
  }
}

/* Optimize for high DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .imageContainer img {
    image-rendering: -webkit-optimize-contrast;
  }
}

/* Animation classes */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.recommendedBadge {
  animation: float 3s ease-in-out infinite;
}

/* Hover effects */
.templateCard:hover .imageContainer::after {
  opacity: 1;
}

.templateCard:hover .templateFeatures {
  transform: translateY(0);
  opacity: 1;
}

/* Accessibility */
.previewButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(128, 97, 221, 0.3);
}

/* Loading state */
.templateCard.loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Landscape mode for mobile devices */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .container {
    padding: 20px;
  }

  .header {
    margin-bottom: 20px;
  }

  .imageContainer {
    height: 300px;
    width: 225px;
  }

  .templateCard {
    padding: 15px;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .templateCard {
    background: rgba(255, 255, 255, 0.95);
  }

  .templateTag {
    background: rgba(240, 242, 245, 0.9);
  }
}

/* Support for reduced motion */
@media (prefers-reduced-motion: reduce) {
  .templateCard,
  .previewButton,
  .imageContainer,
  .templateTag,
  .featureTag {
    transition: none;
  }

  .starIcon {
    animation: none;
  }
}

/* Floating Background Elements */
.backgroundDecoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.floatingCircle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
}

.circle1 {
  width: 300px;
  height: 300px;
  top: -100px;
  right: -50px;
  animation: float1 20s infinite ease-in-out;
}

.circle2 {
  width: 200px;
  height: 200px;
  bottom: 10%;
  left: -50px;
  animation: float2 15s infinite ease-in-out;
}

.circle3 {
  width: 150px;
  height: 150px;
  top: 40%;
  right: 10%;
  animation: float3 18s infinite ease-in-out;
}

.dots {
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0.1;
  background-image: radial-gradient(#8061DD 2px, transparent 2px);
  background-size: 20px 20px;
}

.dots1 {
  top: 20%;
  left: 10%;
  animation: float4 25s infinite linear;
}

.dots2 {
  bottom: 15%;
  right: 15%;
  animation: float5 20s infinite linear;
}

@keyframes float1 {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(-30px, 30px) rotate(5deg); }
}

@keyframes float2 {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(20px, -20px) rotate(-5deg); }
}

@keyframes float3 {
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-15px, 15px) scale(1.1); }
}

@keyframes float4 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes float5 {
  0% { transform: rotate(0deg) scale(1); }
  50% { transform: rotate(180deg) scale(1.1); }
  100% { transform: rotate(360deg) scale(1); }
}

/* Make sure content stays above background */
.header, .templatesGrid {
  position: relative;
  z-index: 1;
}

.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.98);
  padding: 16px 20px;
  border-radius: 20px;
  box-shadow: 
    0 4px 24px rgba(128, 97, 221, 0.15),
    0 2px 8px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(128, 97, 221, 0.1);
  display: flex;
  align-items: flex-start;
  gap: 14px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  max-width: 340px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-left: 4px solid #8061DD;
}

.notification:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 
    0 12px 32px rgba(128, 97, 221, 0.2),
    0 4px 12px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(128, 97, 221, 0.2);
}

.notificationIcon {
  position: relative;
  flex-shrink: 0;
}

.userIcon {
  color: #8061DD;
  background: linear-gradient(135deg, rgba(128, 97, 221, 0.15), rgba(107, 77, 230, 0.15));
  padding: 10px;
  border-radius: 14px;
  box-shadow: 
    0 4px 12px rgba(128, 97, 221, 0.2),
    inset 0 2px 4px rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
}

.notification:hover .userIcon {
  transform: rotate(-10deg) scale(1.1);
  background: linear-gradient(135deg, rgba(128, 97, 221, 0.2), rgba(107, 77, 230, 0.2));
}

.activeIndicator {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(135deg, #4CAF50, #45a049);
  border-radius: 50%;
  border: 2px solid white;
  top: -2px;
  right: -2px;
  animation: pulseGlow 2s infinite;
}

.notificationContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.notificationHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 3px;
}

.userName {
  font-weight: 700;
  color: #2D3748;
  font-size: 15px;
  background: linear-gradient(135deg, #2D3748, #4A5568);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.userLocation {
  color: #718096;
  font-size: 13px;
  position: relative;
  padding-left: 10px;
  font-weight: 500;
}

.userLocation::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #8061DD;
}

.notificationMessage {
  color: #4A5568;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.templateNameNoti {
  color: #8061DD;
  font-weight: 600;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 2px; 
  font-size: 16px;
}

.notificationFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}

.timeAgo {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #A0AEC0;
  font-size: 12px;
  font-weight: 500;
}

.checkIcon {
  color: #4CAF50;
  animation: scaleCheckBounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.notification::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    120deg,
    transparent 0%,
    rgba(255, 255, 255, 0.6) 50%,
    transparent 100%
  );
  animation: shimmer 3s infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.notification:hover::after {
  opacity: 1;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .notification {
    bottom: 16px;
    right: 16px;
    left: 16px;
    max-width: none;
    padding: 16px;
  }

  .userIcon {
    padding: 8px;
  }

  .notificationMessage {
    font-size: 13px;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  .notification {
    border: 2px solid #8061DD;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .notification,
  .notification::after,
  .activeIndicator,
  .checkIcon {
    animation: none;
    transition: none;
  }
}

/* Enhanced template card hover effect when recommended */
.templateCard.recommended {
  position: relative;
  overflow: visible;
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.98));
  transform: translateY(-4px);
  border: none;
}

/* Glowing border effect */
.templateCard.recommended::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(135deg, #6366F1, #8B5CF6, #FF8E53);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderGlow 2s infinite;
}

/* Additional highlight effect */
.templateCard.recommended::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  /* background: linear-gradient(135deg, 
    rgba(99, 102, 241, 0.1), 
    rgba(139, 92, 246, 0.1)
  ); */
  pointer-events: none;
}

/* Enhanced shadow for recommended cards */
.templateCard.recommended {
  box-shadow: 
    0 10px 25px rgba(99, 102, 241, 0.15),
    0 0 0 1px rgba(99, 102, 241, 0.1),
    0 0 40px rgba(139, 92, 246, 0.1);
}

/* Hover effect for recommended cards */
.templateCard.recommended:hover {
  transform: translateY(-8px);
  box-shadow: 
    0 15px 30px rgba(99, 102, 241, 0.2),
    0 0 0 2px rgba(99, 102, 241, 0.15),
    0 0 50px rgba(139, 92, 246, 0.15);
}

/* Animation for the glowing border */
@keyframes borderGlow {
  0% {
    opacity: 0.5;
    background-position: 0% 50%;
  }
  50% {
    opacity: 1;
    background-position: 100% 50%;
  }
  100% {
    opacity: 0.5;
    background-position: 0% 50%;
  }
}

/* Recommended badge enhancements */
.recommendedBadge {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
  /* background: linear-gradient(135deg, #6366F1, #8B5CF6); */
  padding: 8px 16px;
  border-radius: 30px;
  box-shadow: 
    0 4px 15px rgba(99, 102, 241, 0.3),
    0 0 0 2px rgba(255, 255, 255, 0.8);
  animation: float 3s ease-in-out infinite;
}

.recommendedContent {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.starIcon {
  color: #FFD700;
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.5));
  animation: starPulse 2s infinite;
}

.recommendedBadge {
  animation: float 3s ease-in-out infinite;
}

.recommendedContent {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.starIcon {
  color: #FFD700;
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.5));
  animation: starPulse 2s infinite;
}

@keyframes starPulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .templateCard.recommended {
    transform: translateY(-2px);
  }

  .templateCard.recommended:hover {
    transform: translateY(-4px);
  }

  .recommendedBadge {
    top: -10px;
    right: -10px;
    padding: 6px 12px;
  }

  .recommendedContent {
    font-size: 12px;
    gap: 6px;
  }
}

/* Small screens */
@media screen and (max-width: 480px) {
  .recommendedBadge {
    padding: 4px 10px;
  }

  .recommendedContent {
    font-size: 11px;
    gap: 4px;
  }
}

/* Grid layout adjustments for better mobile display */
@media screen and (max-width: 640px) {
  .templatesGrid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
    padding: 12px;
  }
}

@media screen and (max-width: 480px) {
  .templatesGrid {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 8px;
  }
}

/* Loading Modal Styles */
.loadingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(45, 55, 72, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingContent {
  background: linear-gradient(135deg, rgba(45, 55, 72, 0.95), rgba(26, 32, 44, 0.95));
  padding: 40px;
  border-radius: 24px;
  box-shadow: 
    0 0 40px rgba(128, 97, 221, 0.2),
    0 0 100px rgba(107, 77, 230, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
  border: 1px solid rgba(128, 97, 221, 0.2);
  position: relative;
  overflow: hidden;
}

/* Animated background effect */
.loadingContent::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(128, 97, 221, 0.1) 0%,
    rgba(107, 77, 230, 0.05) 30%,
    transparent 70%
  );
  animation: rotate 20s linear infinite;
}

.loadingIcon {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
}

.loadingCircle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: rotate 2s linear infinite;
}

.outerCircle {
  border-top-color: #8061DD;
  animation-duration: 2s;
}

.middleCircle {
  width: 70%;
  height: 70%;
  margin: 15%;
  border-right-color: #6B4DE6;
  animation-duration: 1.5s;
  animation-direction: reverse;
}

.innerCircle {
  width: 40%;
  height: 40%;
  margin: 30%;
  border-bottom-color: #9B7EE2;
  animation-duration: 1s;
}

.aiIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #8061DD;
  filter: drop-shadow(0 0 10px rgba(128, 97, 221, 0.5));
  animation: pulse 2s infinite ease-in-out;
}

.loadingTitle {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.loadingSteps {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.loadingProgress {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.progressBar {
  height: 100%;
  background: linear-gradient(90deg, #8061DD, #6B4DE6);
  border-radius: 3px;
  transition: width 0.3s ease;
  position: relative;
}

.progressBar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

.loadingStep {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  margin-top: 12px;
  font-weight: 500;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Glowing dots */
.loadingDots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8061DD;
  animation: glow 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.tabContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  padding: 0 8px;
  width: 100%;
  justify-content: center;
}

.tabButton {
  padding: 8px 16px;
  border: none;
  border-radius: 12px;
  background: rgba(128, 97, 221, 0.1);
  color: #666;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  min-width: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tabButton:hover {
  background: rgba(128, 97, 221, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(128, 97, 221, 0.15);
}

.activeTab {
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  color: white;
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.3);
}

.activeTab:hover {
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(128, 97, 221, 0.4);
}

.tabButton::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  border-radius: 14px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tabButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(128, 97, 221, 0.3);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .tabContainer {
    gap: 6px;
    margin-bottom: 12px;
  }

  .tabButton {
    padding: 6px 12px;
    font-size: 13px;
    min-width: 80px;
  }
}

@media screen and (max-width: 480px) {
  .tabButton {
    padding: 5px 10px;
    font-size: 12px;
    min-width: 70px;
  }
} 


/* Main Tab Navigation Styles */
.mainTabNavigation {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  padding: 0.5rem;
  position: relative;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  border: 1px solid rgba(128, 97, 221, 0.15);
  box-shadow: 
    0 4px 24px rgba(128, 97, 221, 0.08),
    0 1px 2px rgba(128, 97, 221, 0.02);
  transition: all 0.3s ease;
}

.mainTabBtn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.75rem 1.5rem;
  background: transparent;
  border: none;
  border-radius: 16px;
  color: #666;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  overflow: visible;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  flex: 1;
  max-width: 180px;
  justify-content: center;
  letter-spacing: 0.3px;
}

.mainTabBtn:before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, 
    rgba(128, 97, 221, 0.08), 
    rgba(107, 77, 230, 0.08)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: inherit;
}

.mainTabBtn:hover {
  transform: translateY(-1px);
  color: #8061DD;
}

.mainTabBtn:hover:before {
  opacity: 1;
}

.mainTabBtn svg {
  opacity: 0.7;
  transition: all 0.3s ease;
  font-size: 1.125rem;
}

.mainTabActive {
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  color: white;
  box-shadow: 
    0 4px 12px rgba(128, 97, 221, 0.2),
    0 2px 4px rgba(128, 97, 221, 0.1);
  transform: translateY(-1px);
}

.mainTabActive:hover {
  color: white;
  transform: translateY(-2px);
}

.mainTabActive svg {
  opacity: 1;
  transform: scale(1.1);
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

/* Remove the indicator since we're using background for active state */
.mainTabIndicator {
  display: none;
}

/* Updated pulse animation for active tab */
.mainTabActive:after {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  opacity: 0.15;
  border-radius: inherit;
  z-index: -1;
  animation: mainTabPulse 2s ease-in-out infinite;
}

@keyframes mainTabPulse {
  0%, 100% {
    opacity: 0.15;
    transform: scale(1);
  }
  50% {
    opacity: 0.25;
    transform: scale(1.05);
  }
}

.newBadge {
  position: absolute;
  top: -6px;
  right: -6px;
  background: linear-gradient(135deg, #ff4757, #ff6b81);
  color: white;
  font-size: 10px;
  padding: 3px 6px;
  border-radius: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  box-shadow: 
    0 2px 8px rgba(255, 71, 87, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  z-index: 10;
  transform-origin: center;
  white-space: nowrap;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.mainTabBtn {
  position: relative;
}

/* Enhanced responsive styles */
@media (max-width: 768px) {
  .mainTabNavigation {
    margin: 1.25rem auto;
    padding: 0.375rem;
    gap: 0.5rem;
    max-width: 90%;
  }

  .mainTabBtn {
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    max-width: 190px;
  }

  .mainTabBtn svg {
    font-size: 1rem;
  }
}

 /* ... existing code ... */

/* No Templates Message Styles */
.noTemplatesContainer {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 0 auto;
  max-width: 1200px;
}

.noTemplatesContent {
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 24px;
  box-shadow: 
    0 8px 32px rgba(128, 97, 221, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.05);
  max-width: 500px;
  width: 90%;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(128, 97, 221, 0.1);
  position: relative;
  overflow: hidden;
}

.noTemplatesContent::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, 
    rgba(128, 97, 221, 0.1),
    rgba(107, 77, 230, 0.05)
  );
  border-radius: inherit;
  z-index: 0;
}

.noTemplatesContent > * {
  position: relative;
  z-index: 1;
}

.noTemplatesContent h3 {
  margin: 1.5rem 0 1rem;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.noTemplatesContent p {
  color: #535D79;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-size: 1.1rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.noTemplatesIcon {
  color: #8061DD;
  margin-bottom: 1rem;
  display: inline-flex;
  padding: 1.25rem;
  background: rgba(128, 97, 221, 0.1);
  border-radius: 20px;
  box-shadow: 
    0 8px 16px rgba(128, 97, 221, 0.1),
    inset 0 2px 4px rgba(255, 255, 255, 0.5);
}

.loadingDots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.dot {
  width: 8px;
  height: 8px;
  background: linear-gradient(135deg, #8061DD, #6B4DE6);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 
  40% { 
    transform: scale(1.0);
  }
}

/* Desktop */
@media screen and (max-width: 1200px) {
  .noTemplatesContainer {
    padding: 1.5rem;
  }

  .noTemplatesContent {
    padding: 2rem;
  }

  .noTemplatesContent h3 {
    font-size: 1.5rem;
  }

  .noTemplatesContent p {
    font-size: 1rem;
  }
}

/* Tablet */
@media screen and (max-width: 992px) {
  .noTemplatesContainer {
    min-height: 350px;
    padding: 1rem;
  }

  .noTemplatesContent {
    padding: 1.75rem;
  }

  .noTemplatesIcon {
    padding: 1rem;
  }
}

/* Large Mobile */
@media screen and (max-width: 768px) {
  .noTemplatesContainer {
    min-height: 300px;
  }

  .noTemplatesContent {
    padding: 1.5rem;
    width: 95%;
  }

  .noTemplatesContent h3 {
    font-size: 1.25rem;
    margin: 1rem 0 0.75rem;
  }

  .noTemplatesContent p {
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }

  .noTemplatesIcon {
    padding: 0.875rem;
  }

  .noTemplatesIcon svg {
    width: 36px;
    height: 36px;
  }
}

/* Mobile */
@media screen and (max-width: 576px) {
  .noTemplatesContainer {
    min-height: 250px;
    padding: 1rem 0.5rem;
  }

  .noTemplatesContent {
    padding: 1.25rem;
  }

  .noTemplatesContent h3 {
    font-size: 1.125rem;
  }

  .noTemplatesContent p {
    font-size: 0.875rem;
    padding: 0 0.5rem;
  }

  .noTemplatesIcon {
    padding: 0.75rem;
  }

  .noTemplatesIcon svg {
    width: 32px;
    height: 32px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }
}

/* Small Mobile */
@media screen and (max-width: 360px) {
  .noTemplatesContent {
    padding: 1rem;
  }

  .noTemplatesContent h3 {
    font-size: 1rem;
  }

  .noTemplatesContent p {
    font-size: 0.8125rem;
  }

  .noTemplatesIcon {
    padding: 0.625rem;
  }

  .noTemplatesIcon svg {
    width: 28px;
    height: 28px;
  }
}

/* Handle device orientations */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .noTemplatesContainer {
    min-height: 200px;
  }

  .noTemplatesContent {
    padding: 1rem;
  }

  .noTemplatesIcon {
    margin-bottom: 0.5rem;
  }

  .noTemplatesContent h3 {
    margin: 0.5rem 0;
  }
}

/* Support for reduced motion */
@media (prefers-reduced-motion: reduce) {
  .noTemplatesIcon,
  .dot {
    animation: none;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  .noTemplatesContent {
    background: white;
    border: 2px solid #8061DD;
  }

  .noTemplatesContent h3 {
    background: none;
    -webkit-text-fill-color: initial;
    color: #8061DD;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .noTemplatesContent {
    background: rgba(255, 255, 255, 0.98);
  }

  .noTemplatesContent p {
    color: #4A5568;
  }
}

/* Animations */
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
  50% { transform: translate(-50%, -50%) scale(1.1); opacity: 1; }
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes glow {
  0%, 100% { 
    transform: scale(1);
    opacity: 0.5;
  }
  50% { 
    transform: scale(1.5);
    opacity: 1;
    box-shadow: 0 0 20px rgba(128, 97, 221, 0.5);
  }
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .loadingContent {
    padding: 30px;
  }

  .loadingIcon {
    width: 80px;
    height: 80px;
  }

  .loadingTitle {
    font-size: 24px;
  }

  .loadingSteps {
    font-size: 14px;
  }
}

/* Small screens */
@media screen and (max-width: 480px) {
  .loadingContent {
    padding: 25px;
  }

  .loadingIcon {
    width: 60px;
    height: 60px;
  }

  .loadingTitle {
    font-size: 20px;
  }

  .navbarMobile{
    display: block;
  }

  .loadingSteps {
    font-size: 13px;
  }
}
/* MyFuseButton.module.css */

/* Common button styles */
.button {
  font-family: "Figtree", sans-serif;
  padding: 1rem 2rem;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}

/* Small screen adjustments */
@media (max-width: 640px) {
  .button {
    padding: 0.75rem 2rem;
    font-size: 0.875rem;
  }
}

/* Ghost button styles */
.ghost {
  border: 2px solid;
  background-color: transparent;
}

/* Filled button styles */
.filled {
  background-color: #4d4dff;
  color: #ffffff;
}

/* Hover effect for filled button */
.filledHover {
  background-color: #fdc460;
  color: #4d4dff;
}

/* Hover effect for ghost button */
.ghostHover {
  background-color: transparent;
  color: #4d4dff;
  border-color: #4d4dff;
  transition: background-color 0.6s, color 0.6s, border-color 0.6s;
}

.resumeContainer {
  width: 210mm;  /* A4 width */
  min-height: 297mm; /* A4 height */
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #333;
  line-height: 1.4;
  position: relative;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  page-break-after: avoid;
}

.headerTitle {
  margin: 0;
  color: #2c3e50;
  font-size: 32px;
  font-weight: 600;
}

.headerSubtitle {
  margin: 10px 0;
  color: #7f8c8d;
  font-size: 18px;
}

.contactInfo {
  margin: 10px 0;
  font-size: 14px;
}

.section {
  margin-bottom: 25px;
  page-break-inside: avoid;
}

.sectionTitle {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}

.jobTitle {
  color: #2c3e50;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
}

.jobPeriod {
  color: #7f8c8d;
  margin: 5px 0;
  font-size: 14px;
}

.jobList {
  padding-left: 20px;
  margin: 10px 0;
  line-height: 1.4;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 10px 0;
}

.previewContainer {
  margin-top: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
}

.generateButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generateButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.statusMessage {
  margin-top: 20px;
}

.statusError {
  color: #e74c3c;
}

.statusSuccess {
  color: #2ecc71;
}

/* Print specific styles */
@media print {
  @page {
    size: A4;
    margin: 0;
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.bodys{
     /* background:linear-gradient(132deg, #5003b1, #0f062c 25%, #0f062c 74%, #5003b1); */
     /* background-color: red; */
     
     padding: 0px!important;
     border-radius: 40px !important;
   
     font-family: Arial, sans-serif;
    }

.close {
    
    display: flex;
    justify-content: end;
    position: relative;
    top:-1rem;
    right: -1rem;
}

.closeButton {
    /* color:white; */
    cursor: pointer;
    /* background-color: #4e4e4e; */
    /* background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%); */
     height: 2rem;
     width: 2rem;
    border-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border:2px solid white; */
    /* box-shadow: 0 0 20px 2px rgba(0,0,0,.3); */
}
 
.priceDiv{
    height: 20rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    box-shadow: 0 8px 16px hsla(0,0%,69%,.2);
    cursor: pointer;
    overflow: hidden;
    transition: all .2s;
    padding: 0;
}

.priceDiv:hover {
    transform: translateY(-2px);
    border-color: black;
}
.iconcut{
    /* color:white; */
    cursor: pointer;
} 
.iconcut:hover{ 
  
  /* rotate 90 degree */
  transform: rotate(90deg); 
  /* give smotth rotation  */
  transition: transform 0.5s;
  

}

 

.heading{
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    font-family: "Montserrat", sans-serif;
    line-height: 40px;
}

@media only screen and (max-width: 600px) {
    .bodys{
    background-image: none;
    }
    .heading{ 
        font-size: 1.5rem;
        line-height: 34px;
    }
}
.subheading {
    margin-top: 20px ;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.planheader {
    background: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    
}
.buttons {
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 15px transparent;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    height: 20px;
    justify-content: center;
    min-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    text-decoration: none;
    transition: all .15s linear;
    user-select: none;
    white-space: nowrap;
    text-transform: none;
    /* border-radius: 50px; */
    background: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ddo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.featuresContainer {
     
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  
  .featuresList {
    list-style-type: none;
    padding-left: 0;
    font-size: 0.95rem;
   
  }
  
  .featuresList li {
    margin-bottom: 10px;
  }
  
  .featuresList li strong {
    color: #333;
  }
  
  .paymentContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .termsContainer {
    margin-top: 15px;
    font-size: 0.85rem;
    color: #777;
  }
  .second {
    align-items: flex-start;
    background: #f3f2fd;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
    padding-top: 32px;
    gap: 32px;
     min-height: 460px;
     border-radius: 9px;
  } 

  .price {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    gap: 40px;
   
    padding: 30px;
    
  } 
  .payy {
    display: flex;
    flex-direction: column;
    justify-content:  center;
    width: 100%;

  }
  .navbarheading{
    font-size: 25px;
    font-weight: 700;
    letter-spacing: "0.8px";
    color: black; 
    position: relative;
  }
  .number {
    font-size: 48px;
    line-height: 56px;
    font-weight: 500;
  }
  .bewbumb{
    font-size: 24px;
    line-height: 28px; 
    font-weight: 500;
  }
  .ddool {
    display: flex;
     flex-direction: column;
   
    gap: 4px
  } 
  .dldod {
    display: flex;
    align-items: baseline;
     
    gap: 4px
  }
  .hh {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  .ff{
    font-size: 11px;
  }
  .list {
    font-size: 13px; 
    display: flex;
    justify-content: space-between;
  }
  .ul li {
    list-style: none;
  }
 .ul {
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 1rem;
  }
  .premium {
    font-size: 13px;
    color:  rgb(83, 69, 255);
    position: absolute;
    top: 1.6rem;
    right: 0rem;
    font-weight: 700;
  }
  .ddlo {
    
    position: relative;
    
  }
  .billngperiond {
    font-size: 13px;
    color: #9d9696ee
    
  }
  .pay {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
   padding: 10px;
  }
  .payyy {
    border-radius: 5px;
    border:1px solid #e9e9e9;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .ffo {
    font-weight: 400; 
    /* padding: 10px; */
  }
  .dddodod {
    background-color: #514eea;
    border-radius: 4px;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    padding: 0 4px;
    text-transform: uppercase;
    margin-left: 3px;
  }
  .active {
    border-color: #514eea;
  } 
  .pro {
    color: #514eea;
    font-weight: 600;
  }
  .dsfs {
    font-size: 16px;
    font-weight: 500;
    color: #3f3f3f;
  }
  .mondyback {
    font-size: 10px;
    font-weight: 700;
    color: rgb(17, 133, 17); 
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
  }
  .notlogin {
    background: linear-gradient(to left top, rgb(156, 62, 255), rgb(83, 69, 255));
    color: white;
    width: 100%;
    font-weight: 500; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .4rem;
    border-radius: 10px; 
    cursor: pointer;
  }

.modalWrapper :global(.modal-content) {
  border: none;
  border-radius: 16px;
  overflow: hidden;
}

.modernBody {
  background: linear-gradient(135deg, #2a2171 0%, #4434a0 100%);
  padding: 30px;
  color: white;
  position: relative;
  overflow: hidden;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.contentWrapper {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.leftSection {
  flex: 1;
}

.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.premiumIconContainer {
  width: 80px;
  height: 80px;
  background: rgba(255, 215, 0, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 2px solid rgba(255, 215, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.sparkleEffect {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.sparkle {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: sparkleAnimation 2s infinite;
}

.sparkle:nth-child(1) { top: 20%; left: 20%; width: 4px; height: 4px; }
.sparkle:nth-child(2) { top: 40%; left: 60%; width: 5px; height: 5px; animation-delay: 0.3s; }
.sparkle:nth-child(3) { top: 60%; left: 40%; width: 4px; height: 4px; animation-delay: 0.6s; }
.sparkle:nth-child(4) { top: 30%; left: 80%; width: 3px; height: 3px; animation-delay: 0.9s; }
.sparkle:nth-child(5) { top: 70%; left: 10%; width: 4px; height: 4px; animation-delay: 1.2s; }

@keyframes sparkleAnimation {
  0% { transform: scale(0) rotate(0deg); opacity: 0; }
  50% { transform: scale(1) rotate(180deg); opacity: 1; }
  100% { transform: scale(0) rotate(360deg); opacity: 0; }
}

.modalSparkle {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: sparkleAnimation 3s infinite;
  z-index: 1;
}

.modalSparkle:nth-child(1) { top: 10%; left: 10%; width: 6px; height: 6px; }
.modalSparkle:nth-child(2) { top: 20%; left: 80%; width: 8px; height: 8px; animation-delay: 0.5s; }
.modalSparkle:nth-child(3) { top: 70%; left: 30%; width: 5px; height: 5px; animation-delay: 1s; }
.modalSparkle:nth-child(4) { top: 80%; left: 70%; width: 7px; height: 7px; animation-delay: 1.5s; }
.modalSparkle:nth-child(5) { top: 40%; left: 90%; width: 6px; height: 6px; animation-delay: 2s; }
.modalSparkle:nth-child(6) { top: 60%; left: 5%; width: 8px; height: 8px; animation-delay: 2.5s; }

.premiumImage {
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.5)); 
  transform: scaleX(-1);
}

.title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  text-shadow: 0 2px 10px rgba(255, 215, 0, 0.3);
}

.pricingBox {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.priceTag {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}

.currency {
  font-size: 24px;
  font-weight: 600;
  color: #ffd700;
}

.amount {
  font-size: 42px;
  font-weight: 700;
  margin: 0 5px;
  color: #ffd700;
}

.period {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.savings {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  z-index: 2;
}

.upgradeBtn {
  background: #ffd700;
  color: #2a2171;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);
}

.upgradeBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 215, 0, 0.4);
}

.buttonIcon {
  margin-left: 5px;
  transition: transform 0.2s ease;
}

.upgradeBtn:hover .buttonIcon {
  transform: translateX(3px);
}

.rightSection {
  flex: 1;
}

.featuresTitle {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.proRibbon {
  background: #ffd700;
  color: #2a2171;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  margin-right: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px 15px;
  border-radius: 8px;
  border-left: 3px solid #ffd700;
  transition: all 0.3s ease;
}

.featureItem:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateX(5px);
}

.checkIcon {
  color: #ffd700;
}

.featureTitle {
  font-weight: 500;
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }
  
  .leftSection, .rightSection {
    width: 100%;
  }
}
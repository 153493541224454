/* Outer container */
.mobileFooter {
  background-color: var(--myFuseBlue, #4d4dff); /* bg-myFuseBlue */
  color: white;
  padding-top: 4rem; /* py-16 */
  padding-bottom: 4rem;
  padding-left: 1.5rem; /* px-6 */
  padding-right: 1.5rem;
  width: 100%;
}

/* Inner wrapper */
.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem; /* gap-10 */
  width: 100%;
}

/* Top section: brand, subheading, subscription */
.topSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem; /* gap-5 */
  width: 100%;
}

/* Brand text */
.brand {
  color: white;
  font-weight: bold;
  font-family: "Space Grotesk", sans-serif;
  font-size: 3rem; /* text-5xl (approx.) */
}

.brandHighlight {
  color: var(--myFuseYellow, #fdc460); /* text-myFuseYellow */
}

/* Subheading */
.subheading {
  color: var(--myFuseYellow, #fdc460); /* text-myFuseYellow */
  font-size: 1rem; /* text-base */
  display: flex;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-weight: 500; /* font-medium */
  width: 75%; /* w-3/4 */
}

/* Subscription container */
.subscription {
  margin-top: 1rem; /* mt-4 */
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 9999px; /* rounded-full */
  overflow: hidden;
  padding-left: 0.25rem; /* px-1 */
  padding-right: 0.25rem;
  padding-top: 0.5rem; /* py-2 */
  padding-bottom: 0.5rem;
  width: 100%;
  position: relative;
}

.subscriptionInput {
  padding-left: 1.5rem; /* px-6 */
  padding-right: 1.5rem;
  padding-top: 0.5rem; /* py-2 */
  padding-bottom: 0.5rem;
  font-family: "Figtree", sans-serif;
  color: #1f2937; /* text-gray-800 */
  flex: 1;
  outline: none;
}

.subscriptionButton {
  background-color: var(--myFuseBlue, #4d4dff);
  color: white;
  padding-left: 1.5rem; /* px-6 */
  padding-right: 1.5rem;
  padding-top: 0.5rem; /* py-2 */
  padding-bottom: 0.5rem;
  font-size: 1rem; /* text-base */
  font-family: "Figtree", sans-serif;
  font-weight: 600; /* font-semibold */
  border-radius: 9999px; /* rounded-full */
  margin-right: 0.5rem; /* mr-2 */
  text-transform: uppercase;
  position: absolute;
  right: 0;
  border: none;
}

/* Divider container to simulate divide-y-1 divide-white */
.dividerContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Link group container */
.linkGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem; /* gap-6 */
  padding-bottom: 1.5rem; /* pb-6 */
  width: 100%;
}

/* Link rows */
.linkRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-size: 1rem; /* text-base */
}
.linkRow a {
  display: flex;
  color: white;
  text-decoration: none;
}
.linkRow a:not(:last-child) {
  border-right: 1px solid #e5e7eb;
}
.linkRow a:first-child {
  padding-right: 1rem; /* pr-4 */
}
.linkRow a:last-child {
  padding-left: 1rem; /* pl-4 */
}
.linkRow a:nth-child(2) {
  padding-left: 1rem;
  padding-right: 1rem; /* px-4 */
}

/* Social icons */
.socialIcons {
  display: flex;
  gap: 1.25rem; /* gap-5 */
  font-size: 1.5rem; /* text-2xl */
  color: var(--myellow, #fdc460);
}
.socialIcons a {
  display: flex;
  color: var(--myellow, #fdc460);
}

/* Bottom text */
.bottomText {
  padding-top: 1.5rem; /* pt-6 */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-size: 0.875rem; /* text-sm */
  width: 100%;
  border-top: 1px solid white;
}
